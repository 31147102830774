import React from 'react';

import { lazyRetry } from '@meilleursbiens/utils';

const AuthRegisterChooseScreen = React.lazy(() =>
  lazyRetry(() => import('../screens/auth/register/AuthRegisterChooseScreen'), 'AuthRegisterChooseScreen')
);
const AuthLoginScreen = React.lazy(() => lazyRetry(() => import('../screens/auth/AuthLoginScreen'), 'AuthLoginScreen'));
const AuthRegisterScreen = React.lazy(() =>
  lazyRetry(() => import('../screens/auth/AuthRegisterScreen'), 'AuthRegisterScreen')
);
const AuthRegisterAgencyScreen = React.lazy(() =>
  lazyRetry(() => import('../screens/auth/register/AuthRegisterAgencyScreen'), 'AuthRegisterAgencyScreen')
);
const AuthRefreshScreen = React.lazy(() =>
  lazyRetry(() => import('../screens/auth/AuthRefreshScreen'), 'AuthRefreshScreen')
);
const AuthRegisterSignScreen = React.lazy(() =>
  lazyRetry(() => import('../screens/auth/register/AuthRegisterSignScreen'), 'AuthRegisterSignScreen')
);
const AuthRegisterValidateSubscriptionScreen = React.lazy(() =>
  lazyRetry(
    () => import('../screens/auth/register/AuthRegisterValidateSubscriptionScreen'),
    'AuthRegisterValidateSubscriptionScreen'
  )
);
const AuthForgotPasswordScreen = React.lazy(() =>
  lazyRetry(() => import('../screens/auth/AuthForgotPasswordScreen'), 'AuthForgotPasswordScreen')
);
const AuthAccountBlocked = React.lazy(() =>
  lazyRetry(() => import('../screens/auth/blocked/AuthAccountBlocked'), 'AuthAccountBlocked')
);
const AuthEditPaymentScreen = React.lazy(() =>
  lazyRetry(() => import('../screens/auth/blocked/AuthEditPaymentScreen'), 'AuthEditPaymentScreen')
);
const AuthAccountScreen = React.lazy(() =>
  lazyRetry(() => import('../screens/auth/account/MBAuthAccountScreen'), 'AuthAccountScreen')
);

const AuthRoutes = {
  AUTH_LOGIN: {
    component: AuthLoginScreen,
    url: '/auth/login',
    routeType: 'public',
  },
  AUTH_REGISTER: {
    component: AuthRegisterChooseScreen,
    url: '/auth/register',
    routeType: 'public',
  },
  AUTH_REGISTER_AGENT: {
    component: AuthRegisterScreen,
    url: '/auth/register/agent',
    routeType: 'public',
  },
  AUTH_REGISTER_AGENCY: {
    component: AuthRegisterAgencyScreen,
    url: '/auth/register/agency',
    routeType: 'public',
  },
  AUTH_REFRESH: {
    component: AuthRefreshScreen,
    url: '/auth/refresh',
    routeType: 'public',
  },
  AUTH_REGISTER_VALIDATE: {
    component: AuthRegisterSignScreen,
    url: '/auth/register/validate/:code',
    routeType: 'public',
  },
  AUTH_REGISTER_MANDAT: {
    component: AuthRegisterValidateSubscriptionScreen,
    url: '/auth/register/mandat/validate',
    routeType: 'public',
  },
  AUTH_PASSWORD_VALIDATE: {
    component: AuthForgotPasswordScreen,
    url: '/auth/forgot-password/validate/:code',
    routeType: 'public',
  },
  ACCOUNT_BLOCKED: {
    component: AuthAccountBlocked,
    url: '/auth/blocked',
    routeType: 'public',
  },
  AUTH_PROABONO_EDIT_PAYMENT: {
    component: AuthEditPaymentScreen,
    url: '/auth/edit-payment-method',
    routeType: 'public',
  },
  AUTH_ACCOUNT_SCREEN: {
    component: AuthAccountScreen,
    url: '/auth/account',
    routeType: 'private.validated',
  },
};

export default AuthRoutes;
