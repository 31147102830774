import { MBProUser } from '@meilleursbiens/types';

import MBProAPI from '../../../MBApi';
import { MBApi } from '../../../index';

export default class AuthRegisterAgencyAPI {
  static generateContrat = () => {
    return MBApi.url(`/v1/mbpro/auth/register/contract`).needAuth().get<{ url: string }>();
  };

  static uploadSign(file: File) {
    const formData = new FormData();
    formData.append('signature', file);

    return MBProAPI.url(`/v1/mbpro/auth/register/contract/upload/sign`)
      .needAuth()
      .contentFormData()
      .formData(formData)
      .postUpload();
  }

  static startSubscription = () => {
    return MBApi.url(`/v2/auth/agence/register/subscription/start`).needAuth().post();
  };

  static sendSMS = () => {
    return MBApi.url(`/v1/mbpro/auth/register/contract/sms`).needAuth().get();
  };

  static validateCode = (code: string) => {
    return MBApi.url(`/v1/mbpro/auth/register/contract/sms/validate/` + code)
      .needAuth()
      .get<{ user: MBProUser }>();
  };
}
