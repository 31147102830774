import { MBApi } from '../../../index';

export default class AuthRegisterAgencyAPI {
  static registerAgency = (data: any) => {
    return MBApi.url(`/v2/auth/agence/register`).parameters(data).post();
  };

  static generateContrat = () => {
    return MBApi.url(`/v2/auth/agence/register/contract`).needAuth().get<{
      url: string;
    }>();
  };

  static startSubscription = () => {
    return MBApi.url(`/v2/auth/agence/register/subscription/start`).needAuth().post();
  };
}
