import React, { useEffect } from 'react';

import { use100vh } from '@meilleursbiens/hooks';
import { TipsUtils } from '@meilleursbiens/utils';

import { Flex, HStack, Progress, Text, useColorModeValue } from '@chakra-ui/react';
import { InformationCircleIcon } from '@heroicons/react/24/outline';

import { fadeInUp } from '../../Animations';
import { Logo } from '../Sidebar';

export interface LoadingPageProps {
  isFullParent?: boolean;
  hasTips?: boolean;
  tipsCategory?: 'agent' | 'seller';
}

const LoadingPage = (props: LoadingPageProps) => {
  const fullHeight = use100vh();

  const [tip, setTip] = React.useState<string>('');

  useEffect(() => {
    if (props.hasTips) {
      if (props.tipsCategory === 'agent') {
        setTip(TipsUtils.getRandomAgentTip());
      } else {
        setTip(TipsUtils.getRandomSellerTip());
      }
    }
  }, []);

  return (
    <Flex
      w={props.isFullParent ? '100%' : '100vw'}
      h={props.isFullParent ? '100%' : fullHeight + 'px'}
      justifyContent={'center'}
      direction={'column'}
      alignItems={'center'}
      bg={useColorModeValue('white', 'gray.800')}
      animation={`${fadeInUp} 0.8s ease`}
      position={'fixed'}
      margin={'auto'}
      top={0}
      left={0}
      right={0}
      bottom={0}
      overflow={'hidden'}
    >
      <Logo height={'23px'} />
      <Progress colorScheme={'red'} mt={8} isIndeterminate w={'130px'} h={'3px'} size={'xs'} />

      {props.hasTips && (
        <Flex
          alignSelf={'flex-end'}
          direction={'column'}
          alignItems={'center'}
          textAlign={'center'}
          w={'100%'}
          maxW={'80%'}
          marginLeft={'auto'}
          marginRight={'auto'}
          pb={'2.5rem'}
          position={'fixed'}
          bottom={0}
          marginX={'auto'}
          left={0}
          right={0}
        >
          <HStack animation={`${fadeInUp} 0.3s ease`} spacing={1}>
            <Flex gap={'0.25rem'} mb={'0.25rem'}>
              <InformationCircleIcon width={15} />
              <Text fontSize={'md'} fontWeight={'bold'}>
                Le saviez-vous ?{' '}
              </Text>
            </Flex>
          </HStack>
          <Text fontSize={'md'} color={useColorModeValue('gray.600', 'gray.300')}>
            {tip}
          </Text>
        </Flex>
      )}
    </Flex>
  );
};

export default LoadingPage;
