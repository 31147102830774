import React, { useEffect } from 'react';

import { CountryPhone as Countries, CountryPhoneType } from '@meilleursbiens/constants';

import { SearchIcon } from '@chakra-ui/icons';
import {
  Box,
  HStack,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Text,
  forwardRef,
  useDisclosure,
} from '@chakra-ui/react';

import parsePhoneNumber, {
  CountryCode,
  formatIncompletePhoneNumber,
  validatePhoneNumberLength,
} from 'libphonenumber-js';

import TextInput, { TextInputProps } from '../TextInput/TextInput';
import { CountryType, Phone } from './PhoneInputUI';

export interface PhoneInputProps extends Omit<Omit<TextInputProps, 'inputMaskChar'>, 'inputMask'> {
  defaultCountry?: CountryType[2];
  theme?: 'outline' | 'unstyled';
  countryProps?: React.ComponentPropsWithRef<typeof Phone.Country>;
  numberProps?: React.ComponentPropsWithRef<typeof Phone.Number>;
}

const PhoneInput = forwardRef<TextInputProps, 'input'>((props: PhoneInputProps, ref: any) => {
  const value = props.values[props.name] || '';
  const defaultTheme = props.theme ? props.theme : 'outline';

  const disclosure = useDisclosure();

  const [valueCloned, setValueCloned] = React.useState<string>(value);
  const [selectedCountry, setSelectedCountry] = React.useState<CountryPhoneType>(Countries[0]);

  const [searchResults, setSearchResults] = React.useState<CountryPhoneType[]>(Countries);
  const [searchInput, setSearchInput] = React.useState('');

  const [isValid, setIsValid] = React.useState(true);
  const [errorMessage, setErrorMessage] = React.useState('');

  useEffect(() => {
    // Si pas de numéro on va déterminer le pays du client
    if (!value) {
      detectBrowserCountry();
    } else {
      getCountryCodeFromPhoneNumber(value);
    }
  }, []);

  useEffect(() => {
    if (valueCloned.startsWith('+')) {
      getCountryCodeFromPhoneNumber(valueCloned);
    }

    const formated = formatIncompletePhoneNumber(valueCloned, selectedCountry.code as CountryCode);

    setValueCloned(formated);

    if (formated.length < 4) {
      setIsValid(true);
      return;
    }

    const validateLength = validatePhoneNumberLength(valueCloned, selectedCountry.code as CountryCode);

    if (!validateLength) {
      setIsValid(true);
      return;
    }

    if (validateLength === 'TOO_LONG') {
      setIsValid(false);
      setErrorMessage('Le numéro de téléphone est trop long');
    }

    if (validateLength === 'TOO_SHORT') {
      setIsValid(false);
      setErrorMessage('Le numéro de téléphone est trop court');
    }
  }, [valueCloned, selectedCountry]);

  const detectBrowserCountry = () => {
    const country = navigator.language.split('-')[1];
    const countryPhone = Countries.find((c) => c.code === country);
    if (countryPhone) setSelectedCountry(countryPhone);
    else setSelectedCountry(Countries[0]);
  };

  const getCountryCodeFromPhoneNumber = (phoneNumber: string) => {
    const number = parsePhoneNumber(phoneNumber);
    if (number) {
      const country = Countries.find((c) => c.code === number.country);
      if (country) setSelectedCountry(country);
      else console.error('Impossible de trouver le pays du numéro de téléphone');
    } else {
      console.error('Impossible de trouver le pays du numéro de téléphone');
    }
  };

  const validatePhoneNumber = (phoneNumber: string) => {
    const number = parsePhoneNumber(phoneNumber, selectedCountry.code as CountryCode);

    console.log('number', number);

    if (!number) {
      setIsValid(false);
      setValueCloned(phoneNumber);
      setErrorMessage('Le numéro de téléphone est invalide');

      return;
    }

    const isPossible = number.isPossible();
    const isValid = number.isValid();

    if (isPossible && isValid) {
      setIsValid(true);
      const format = number.formatInternational();
      setValueCloned(format);

      console.log('format', format);
      props.handleChange({
        // @ts-ignore
        target: {
          name: props.name,
          value: format,
        },
      });
    } else {
      setIsValid(false);
      setValueCloned(phoneNumber);
      setErrorMessage('Le numéro de téléphone est invalide');
    }
  };

  const _selectCountry = (country: CountryPhoneType) => {
    const currentCountry = Countries.find((c) => c.code === country.code);
    if (currentCountry) setValueCloned(valueCloned.replace(selectedCountry.phoneCode, country.phoneCode));
    setSelectedCountry(country);
    disclosure.onClose();
  };

  const _onSearch = (input: string) => {
    if (input === '') return setSearchResults(Countries);

    setSearchInput(input);
    const results = Countries.filter((c) => c.name.toLowerCase().includes(input.toLowerCase()));
    setSearchResults(results);
  };

  return (
    <Box w={'100%'}>
      <Text fontSize={'xs'} fontWeight={500} mb={1}>
        {props.label} {props.isRequired && <span style={{ color: '#BB2030' }}>*</span>}
      </Text>
      <HStack spacing={0}>
        <Popover
          isOpen={disclosure.isOpen}
          onClose={() => {
            disclosure.onClose();
            setSearchInput('');
          }}
          closeOnBlur={true}
        >
          <PopoverTrigger>
            <IconButton
              aria-label={'Pays'}
              variant={defaultTheme}
              borderTopRightRadius={0}
              borderBottomRightRadius={0}
              onClick={disclosure.onOpen}
              icon={
                <Box>
                  <Text fontSize={'lg'}>{selectedCountry.flagEmoji}</Text>

                  <Text
                    fontSize={'7px'}
                    color={'muted'}
                    position={'absolute'}
                    top={'18px'}
                    left={selectedCountry.phoneCode.length > 4 ? 2 : 4}
                    bg={'white'}
                    borderRadius={'sm'}
                    boxShadow={'lg'}
                  >
                    {selectedCountry.phoneCode}
                  </Text>
                </Box>
              }
            />
          </PopoverTrigger>
          <PopoverContent>
            <PopoverArrow />
            <PopoverCloseButton />
            <PopoverHeader p={0}>
              <InputGroup variant={'ghost'}>
                <InputLeftElement pointerEvents="none">
                  <SearchIcon color="muted" />
                </InputLeftElement>
                <Input type="text" placeholder="Rechercher un indicatif" onChange={(e) => _onSearch(e.target.value)} />
              </InputGroup>
            </PopoverHeader>
            <PopoverBody p={0} maxH={'200px'} overflow={'auto'}>
              {searchResults.map((c) => {
                return (
                  <Box
                    key={'country-' + c.code}
                    px={3}
                    py={1}
                    borderBottomWidth={1}
                    cursor={'pointer'}
                    onClick={() => _selectCountry(c)}
                  >
                    <HStack>
                      <Text fontSize={'lg'}>{c.flagEmoji}</Text>
                      <Text fontSize={'xs'}>
                        {c.name} ({c.phoneCode})
                      </Text>
                    </HStack>
                  </Box>
                );
              })}
            </PopoverBody>
          </PopoverContent>
        </Popover>

        <TextInput
          ref={ref}
          type="tel"
          name={props.name}
          height={'33px'}
          _placeholder={{ fontSize: 'xs' }}
          value={valueCloned}
          errors={props.errors}
          touched={props.touched}
          isHorizontal={props.isHorizontal}
          values={props.values}
          handleChange={(e) => {
            setValueCloned(e.target.value);
          }}
          handleBlur={(e) => {
            validatePhoneNumber(e.target.value);
          }}
          borderTopLeftRadius={0}
          borderBottomLeftRadius={0}
        />
      </HStack>
      <Text fontSize={'xs'} color={'red.500'} mt={1}>
        {!isValid && errorMessage}
      </Text>
    </Box>
  );
});

export default PhoneInput;
