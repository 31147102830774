import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';

type AircallPhoneProps = {
  phoneUrl?: string;
  integrationToLoad?: Record<string, any>;
  path?: string;
  debug?: boolean;
  size?: 'big' | 'small' | 'auto';
  onLogin?: (data: { user: Record<string, any>; settings?: Record<string, any> }) => void;
  onLogout?: () => void;
  windowObj?: Window;
};

type PhoneWindow = {
  source: Window;
  origin: string;
} | null;

type EventCallback = (data?: any) => void;

type AircallPhoneMethods = {
  send: (eventName: string, data?: any, callback?: EventCallback) => void;
  on: (eventName: string, callback: EventCallback) => void;
  removeListener: (eventName: string) => boolean;
  isLoggedIn: (callback: (success: boolean) => void) => void;
};

const AircallPhone = forwardRef<AircallPhoneMethods, AircallPhoneProps>((props, ref) => {
  const {
    phoneUrl = 'https://phone.aircall.io',
    integrationToLoad,
    path,
    debug = false,
    size = 'small',
    onLogin,
    onLogout,
    windowObj = window,
  } = props;

  const URL_REGEX = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/gi;

  const [phoneLoginState, setPhoneLoginState] = useState(false);
  const [integrationSettings, setIntegrationSettings] = useState<Record<string, any>>({});
  const [userSettings, setUserSettings] = useState<Record<string, any>>({});
  const eventsRegistered = useRef<Record<string, EventCallback>>({});

  const phoneWindow = useRef<PhoneWindow>(null);
  const iframeRef = useRef<HTMLIFrameElement>(null);

  // Logging function
  const log = (action: string, ...args: any[]) => {
    if (!debug) return;
    if (typeof action !== 'string') {
      throw new Error('[AircallPhone] [log] Action must be a string');
    }
    if (console[action as keyof Console]) {
      // @ts-ignore
      console[action as keyof Console](...args);
    } else {
      console.info(...args);
    }
  };

  // Create iframe based on size
  const getIframeStyle = (): React.CSSProperties => {
    switch (size) {
      case 'big':
        return { height: '666px', width: '376px', border: 'none', borderRadius: '8px' };
      case 'small':
        return { height: '600px', width: '376px', border: 'none', borderRadius: '8px' };
      case 'auto':
        return { height: '100%', width: '100%', border: 'none', borderRadius: '8px' };
      default:
        return { height: '666px', width: '376px', border: 'none', borderRadius: '8px' };
    }
  };

  // Generate URL to load in iframe
  const getUrlToLoad = (): string => {
    const validPhoneUrl = URL_REGEX.test(phoneUrl) ? phoneUrl : 'https://phone.aircall.io';
    return `${validPhoneUrl}?integration=generic`;
  };

  // Handle initialization message
  const handleInitMessage = (event: MessageEvent) => {
    phoneWindow.current = {
      source: event.source as Window,
      origin: event.origin,
    };

    if (event.data.value) {
      setUserSettings(event.data.value);
    }

    // Respond to initialization
    phoneWindow.current.source.postMessage({ name: 'apm_app_isready', path }, phoneWindow.current.origin);

    // Request integration settings if available
    if (integrationToLoad) {
      phoneWindow.current.source.postMessage(
        { name: 'apm_app_get_settings', value: integrationToLoad },
        phoneWindow.current.origin
      );
    } else {
      // Trigger onLogin callback if no integration settings to load
      if (onLogin && !phoneLoginState) {
        setPhoneLoginState(true);
        const data = { user: userSettings };
        if (Object.keys(integrationSettings).length > 0) {
          // @ts-ignore
          data.settings = integrationSettings;
        }
        onLogin(data);
      }
    }
  };

  // Handle incoming messages
  const messageListener = (event: MessageEvent) => {
    log('info', '[AircallPhone] [messageListener] Received event', event);
    const { data } = event;
    const matchPrefixRegex = /^apm_phone_/;

    if (!data || !data.name || !matchPrefixRegex.test(data.name)) {
      return;
    }

    switch (data.name) {
      case 'apm_phone_loaded':
        handleInitMessage(event);
        break;

      case 'apm_phone_integration_settings':
        if (data.value) {
          setIntegrationSettings(data.value);
          // Trigger onLogin callback after receiving settings
          if (onLogin && !phoneLoginState) {
            setPhoneLoginState(true);
            const loginData = { user: userSettings, settings: data.value };
            onLogin(loginData);
          }
        }
        break;

      case 'apm_phone_logout':
        // Reset data
        setPhoneLoginState(false);
        setIntegrationSettings({});
        setUserSettings({});
        phoneWindow.current = null;
        // Trigger onLogout callback
        if (onLogout) onLogout();
        break;

      default:
        // Check if the event matches any registered event
        const eventName = data.name.replace('apm_phone_', '');
        if (eventsRegistered.current[eventName]) {
          eventsRegistered.current[eventName](data.value);
        }
        break;
    }
  };

  // Register event listeners
  useEffect(() => {
    windowObj.addEventListener('message', messageListener, false);
    return () => {
      windowObj.removeEventListener('message', messageListener, false);
    };
  }, [userSettings, integrationSettings, phoneLoginState]);

  // Expose methods via ref
  useImperativeHandle(ref, () => ({
    send,
    on,
    removeListener,
    isLoggedIn,
  }));

  // Send message to iframe
  const send = (eventName: string, data?: any, callback?: EventCallback) => {
    if (typeof data === 'function' && !callback) {
      callback = data;
      data = undefined;
    }

    if (!eventName) {
      handleSendError({ code: 'no_event_name' }, callback);
      return false;
    }

    if (phoneWindow.current && phoneWindow.current.source) {
      let responseTimeout: NodeJS.Timeout | null = null;
      const timeoutLimit = 2000;

      // Send the message
      phoneWindow.current.source.postMessage({ name: `apm_app_${eventName}`, value: data }, phoneWindow.current.origin);

      // Listen for the response
      const responseHandler = (response: any) => {
        if (response && response.success === false) {
          handleSendError({ code: response.errorCode, message: response.errorMessage }, callback);
        } else if (response && response.success === true) {
          // @ts-ignore
          if (callback) callback(true, response.data);
        } else {
          handleSendError({ code: 'invalid_response' }, callback);
        }
      };

      on(`${eventName}_response`, responseHandler);

      // Set timeout for response
      responseTimeout = setTimeout(() => {
        removeListener(`${eventName}_response`);
        handleSendError({ code: 'no_answer' }, callback);
      }, timeoutLimit);

      return () => responseTimeout && clearTimeout(responseTimeout);
    } else {
      handleSendError({ code: 'not_ready' }, callback);
      return false;
    }
  };

  // Handle errors when sending messages
  const handleSendError = (error: { code: string; message?: string }, callback?: EventCallback) => {
    if (!error || !error.code) {
      error = { code: 'unknown_error' };
    }

    if (!error.message) {
      switch (error.code) {
        case 'unknown_error':
          error.message = 'Unknown error. Contact Aircall developers dev@aircall.io';
          break;
        case 'no_event_name':
          error.message = 'Invalid parameter eventName. Expected a non-empty string';
          break;
        case 'not_ready':
          error.message = 'Aircall Phone has not been identified yet or is not ready. Wait for "onLogin" callback';
          break;
        case 'no_answer':
          error.message = 'No answer from the phone. Check if the phone is logged in';
          break;
        case 'invalid_response':
          error.message = 'Invalid response from the phone. Contact Aircall developers dev@aircall.io';
          break;
        default:
          error.message = 'Generic error message';
          break;
      }
    }

    log('error', `[AircallPhone] [send] ${error.message}`);

    if (typeof callback === 'function') {
      // @ts-ignore
      callback(false, error);
    }
  };

  // Register event listeners
  const on = (eventName: string, callback: EventCallback) => {
    if (!eventName || typeof callback !== 'function') {
      throw new Error('[AircallPhone] [on] Invalid parameters. Expected a non-empty string and a function');
    }
    eventsRegistered.current[eventName] = callback;
  };

  // Remove event listeners
  const removeListener = (eventName: string): boolean => {
    if (!eventsRegistered.current[eventName]) {
      return false;
    }
    delete eventsRegistered.current[eventName];
    return true;
  };

  // Check if the user is logged in
  const isLoggedIn = (callback: (success: boolean) => void) => {
    send('is_logged_in', (success: boolean, data: any) => {
      if (typeof callback === 'function') {
        callback(success);
      }
    });
  };

  // Create iframe on mount
  useEffect(() => {
    if (iframeRef.current) {
      const iframe = iframeRef.current;
      iframe.src = getUrlToLoad();
      const style = getIframeStyle();
      // @ts-ignore
      iframe.style.height = style.height!;
      // @ts-ignore
      iframe.style.width = style.width!;
      // @ts-ignore
      iframe.style.border = style.border!;
      iframe.style.borderRadius = '8px';
      iframe.allow = 'microphone; autoplay; clipboard-read; clipboard-write; hid';
      iframe.title = 'Aircall Phone';
    }
  }, [phoneUrl, size]);

  return <iframe ref={iframeRef} allow={'microphone; autoplay; clipboard-read; clipboard-write; hid'} />;
});

export default AircallPhone;
