import { Component } from 'react';
import connect from 'react-redux/es/connect/connect';

import { S3Utils, ToastUtils } from '@meilleursbiens/utils';

import { Drawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerHeader, DrawerOverlay } from '@chakra-ui/react';

import MBProDashboardAPI from '../../../../api/requests/MBPro/MBProDashboardAPI';
import { openMandatModal } from '../../../../redux/actions/modals/ModalActions';

class UsefullLinksModalComponent extends Component {
  state = {
    isLoading: true,
    links: [],
  };

  componentDidMount() {
    this._fetchAPI();
  }

  _fetchAPI() {
    this.setState({ isLoading: true });
    MBProDashboardAPI.usefullLink()
      .then((r) => {
        this.setState({ links: r.links, isLoading: false });
      })
      .catch((e) => {
        ToastUtils.showError(e);
      });
  }

  render() {
    return (
      <Drawer
        placement={'bottom'}
        onClose={() => {
          this.props.onClose();
        }}
        isOpen={this.props.isOpen}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader borderBottomWidth="1px">Liens Pratiques</DrawerHeader>
          <DrawerBody>
            <div className="columns is-multiline">
              <div
                className="column is-2 is-6-tablet-only is-12-mobile"
                key={'usefull-link-mini-site'}
                onClick={() => {
                  this.props.onClose();
                  setTimeout(() => {
                    this.props.openMandatModal();
                  }, 400);
                }}
              >
                <div className="card card-usefull-link">
                  <div className="card-image">
                    <figure className="image is-4by3">
                      <img
                        src={S3Utils.url('link_4fsd54f4sd47h98g798h7fd65165bc19b4fd.png')}
                        alt={'Retourner un mandat'}
                      />
                    </figure>
                  </div>
                  <div className="card-content">
                    <h5 className="title is-5 is-font-title">Retourner un mandat</h5>
                    <p>Retourner un mandat de vente</p>
                  </div>
                </div>
              </div>
              {this.state.links.map((l, k) => (
                <div
                  className="column is-2 is-6-tablet-only is-12-mobile"
                  key={'usefull-link-' + k}
                  onClick={() => {
                    // CARD PM
                    if (l.id == 6) {
                      if (this.props.user.hasOwnProperty('card_pm')) window.open(this.props.user.card_pm);
                      else window.open(l.link);
                    } else {
                      window.open(l.link);
                    }
                  }}
                >
                  <div className="card card-usefull-link">
                    <div className="card-image">
                      <figure className="image is-4by3">
                        <img src={S3Utils.url(l.image)} alt={l.name} />
                      </figure>
                    </div>
                    <div className="card-content">
                      <h5 className="title is-5 is-font-title">{l.name}</h5>
                      <p>{l.description}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    openMandatModal: (data) => {
      dispatch(openMandatModal(data));
    },
  };
};

const mapStateToProps = (state) => ({
  isLogged: state.auth.isLogged,
  user: state.auth.user,
});

export default connect(mapStateToProps, mapDispatchToProps)(UsefullLinksModalComponent);
