type CountryPhoneType = {
  code: string;
  name: string;
  phoneCode: string;
  flagEmoji: string;
};

const CountryPhone: CountryPhoneType[] = [
  { code: 'FR', name: 'France', phoneCode: '+33', flagEmoji: '🇫🇷' },
  { code: 'GF', name: 'French Guiana', phoneCode: '+594', flagEmoji: '🇬🇫' },
  { code: 'GP', name: 'Guadeloupe', phoneCode: '+590', flagEmoji: '🇬🇵' },
  { code: 'MQ', name: 'Martinique', phoneCode: '+596', flagEmoji: '🇲🇶' },
  { code: 'RE', name: 'Réunion', phoneCode: '+262', flagEmoji: '🇷🇪' },
  { code: 'YT', name: 'Mayotte', phoneCode: '+262', flagEmoji: '🇾🇹' },
  { code: 'NC', name: 'New Caledonia', phoneCode: '+687', flagEmoji: '🇳🇨' },
  { code: 'PF', name: 'French Polynesia', phoneCode: '+689', flagEmoji: '🇵🇫' },
  { code: 'PM', name: 'Saint Pierre and Miquelon', phoneCode: '+508', flagEmoji: '🇵🇲' },
  { code: 'WF', name: 'Wallis and Futuna', phoneCode: '+681', flagEmoji: '🇼🇫' },
  { code: 'AF', name: 'Afghanistan', phoneCode: '+93', flagEmoji: '🇦🇫' },
  { code: 'AL', name: 'Albania', phoneCode: '+355', flagEmoji: '🇦🇱' },
  { code: 'DZ', name: 'Algeria', phoneCode: '+213', flagEmoji: '🇩🇿' },
  { code: 'AD', name: 'Andorra', phoneCode: '+376', flagEmoji: '🇦🇩' },
  { code: 'AO', name: 'Angola', phoneCode: '+244', flagEmoji: '🇦🇴' },
  { code: 'AR', name: 'Argentina', phoneCode: '+54', flagEmoji: '🇦🇷' },
  { code: 'AM', name: 'Armenia', phoneCode: '+374', flagEmoji: '🇦🇲' },
  { code: 'AU', name: 'Australia', phoneCode: '+61', flagEmoji: '🇦🇺' },
  { code: 'AT', name: 'Austria', phoneCode: '+43', flagEmoji: '🇦🇹' },
  { code: 'AZ', name: 'Azerbaijan', phoneCode: '+994', flagEmoji: '🇦🇿' },
  { code: 'BS', name: 'Bahamas', phoneCode: '+1-242', flagEmoji: '🇧🇸' },
  { code: 'BH', name: 'Bahrain', phoneCode: '+973', flagEmoji: '🇧🇭' },
  { code: 'BD', name: 'Bangladesh', phoneCode: '+880', flagEmoji: '🇧🇩' },
  { code: 'BY', name: 'Belarus', phoneCode: '+375', flagEmoji: '🇧🇾' },
  { code: 'BE', name: 'Belgium', phoneCode: '+32', flagEmoji: '🇧🇪' },
  { code: 'BZ', name: 'Belize', phoneCode: '+501', flagEmoji: '🇧🇿' },
  { code: 'BJ', name: 'Benin', phoneCode: '+229', flagEmoji: '🇧🇯' },
  { code: 'BT', name: 'Bhutan', phoneCode: '+975', flagEmoji: '🇧🇹' },
  { code: 'BO', name: 'Bolivia', phoneCode: '+591', flagEmoji: '🇧🇴' },
  { code: 'BA', name: 'Bosnia and Herzegovina', phoneCode: '+387', flagEmoji: '🇧🇦' },
  { code: 'BW', name: 'Botswana', phoneCode: '+267', flagEmoji: '🇧🇼' },
  { code: 'BR', name: 'Brazil', phoneCode: '+55', flagEmoji: '🇧🇷' },
  { code: 'BN', name: 'Brunei', phoneCode: '+673', flagEmoji: '🇧🇳' },
  { code: 'BG', name: 'Bulgaria', phoneCode: '+359', flagEmoji: '🇧🇬' },
  { code: 'BF', name: 'Burkina Faso', phoneCode: '+226', flagEmoji: '🇧🇫' },
  { code: 'BI', name: 'Burundi', phoneCode: '+257', flagEmoji: '🇧🇮' },
  { code: 'KH', name: 'Cambodia', phoneCode: '+855', flagEmoji: '🇰🇭' },
  { code: 'CM', name: 'Cameroon', phoneCode: '+237', flagEmoji: '🇨🇲' },
  { code: 'CA', name: 'Canada', phoneCode: '+1', flagEmoji: '🇨🇦' },
  { code: 'CV', name: 'Cape Verde', phoneCode: '+238', flagEmoji: '🇨🇻' },
  { code: 'CF', name: 'Central African Republic', phoneCode: '+236', flagEmoji: '🇨🇫' },
  { code: 'TD', name: 'Chad', phoneCode: '+235', flagEmoji: '🇹🇩' },
  { code: 'CL', name: 'Chile', phoneCode: '+56', flagEmoji: '🇨🇱' },
  { code: 'CN', name: 'China', phoneCode: '+86', flagEmoji: '🇨🇳' },
  { code: 'CO', name: 'Colombia', phoneCode: '+57', flagEmoji: '🇨🇴' },
  { code: 'KM', name: 'Comoros', phoneCode: '+269', flagEmoji: '🇰🇲' },
  { code: 'CG', name: 'Congo (Congo-Brazzaville)', phoneCode: '+242', flagEmoji: '🇨🇬' },
  { code: 'CD', name: 'Congo (Democratic Republic)', phoneCode: '+243', flagEmoji: '🇨🇩' },
  { code: 'CR', name: 'Costa Rica', phoneCode: '+506', flagEmoji: '🇨🇷' },
  { code: 'HR', name: 'Croatia', phoneCode: '+385', flagEmoji: '🇭🇷' },
  { code: 'CU', name: 'Cuba', phoneCode: '+53', flagEmoji: '🇨🇺' },
  { code: 'CY', name: 'Cyprus', phoneCode: '+357', flagEmoji: '🇨🇾' },
  { code: 'CZ', name: 'Czech Republic', phoneCode: '+420', flagEmoji: '🇨🇿' },
  { code: 'DK', name: 'Denmark', phoneCode: '+45', flagEmoji: '🇩🇰' },
  { code: 'DJ', name: 'Djibouti', phoneCode: '+253', flagEmoji: '🇩🇯' },
  { code: 'DM', name: 'Dominica', phoneCode: '+1-767', flagEmoji: '🇩🇲' },
  { code: 'DO', name: 'Dominican Republic', phoneCode: '+1-809', flagEmoji: '🇩🇴' },
  { code: 'EC', name: 'Ecuador', phoneCode: '+593', flagEmoji: '🇪🇨' },
  { code: 'EG', name: 'Egypt', phoneCode: '+20', flagEmoji: '🇪🇬' },
  { code: 'SV', name: 'El Salvador', phoneCode: '+503', flagEmoji: '🇸🇻' },
  { code: 'GQ', name: 'Equatorial Guinea', phoneCode: '+240', flagEmoji: '🇬🇶' },
  { code: 'ER', name: 'Eritrea', phoneCode: '+291', flagEmoji: '🇪🇷' },
  { code: 'EE', name: 'Estonia', phoneCode: '+372', flagEmoji: '🇪🇪' },
  { code: 'SZ', name: 'Eswatini', phoneCode: '+268', flagEmoji: '🇸🇿' },
  { code: 'ET', name: 'Ethiopia', phoneCode: '+251', flagEmoji: '🇪🇹' },
  { code: 'FJ', name: 'Fiji', phoneCode: '+679', flagEmoji: '🇫🇯' },
  { code: 'FI', name: 'Finland', phoneCode: '+358', flagEmoji: '🇫🇮' },
  { code: 'GA', name: 'Gabon', phoneCode: '+241', flagEmoji: '🇬🇦' },
  { code: 'GM', name: 'Gambia', phoneCode: '+220', flagEmoji: '🇬🇲' },
  { code: 'GE', name: 'Georgia', phoneCode: '+995', flagEmoji: '🇬🇪' },
  { code: 'DE', name: 'Germany', phoneCode: '+49', flagEmoji: '🇩🇪' },
  { code: 'GH', name: 'Ghana', phoneCode: '+233', flagEmoji: '🇬🇭' },
  { code: 'GR', name: 'Greece', phoneCode: '+30', flagEmoji: '🇬🇷' },
  { code: 'GD', name: 'Grenada', phoneCode: '+1-473', flagEmoji: '🇬🇩' },
  { code: 'GT', name: 'Guatemala', phoneCode: '+502', flagEmoji: '🇬🇹' },
  { code: 'GN', name: 'Guinea', phoneCode: '+224', flagEmoji: '🇬🇳' },
  { code: 'GW', name: 'Guinea-Bissau', phoneCode: '+245', flagEmoji: '🇬🇼' },
  { code: 'GY', name: 'Guyana', phoneCode: '+592', flagEmoji: '🇬🇾' },
  { code: 'HT', name: 'Haiti', phoneCode: '+509', flagEmoji: '🇭🇹' },
  { code: 'HN', name: 'Honduras', phoneCode: '+504', flagEmoji: '🇭🇳' },
  { code: 'HU', name: 'Hungary', phoneCode: '+36', flagEmoji: '🇭🇺' },
  { code: 'IS', name: 'Iceland', phoneCode: '+354', flagEmoji: '🇮🇸' },
  { code: 'IN', name: 'India', phoneCode: '+91', flagEmoji: '🇮🇳' },
  { code: 'ID', name: 'Indonesia', phoneCode: '+62', flagEmoji: '🇮🇩' },
  { code: 'IR', name: 'Iran', phoneCode: '+98', flagEmoji: '🇮🇷' },
  { code: 'IQ', name: 'Iraq', phoneCode: '+964', flagEmoji: '🇮🇶' },
  { code: 'IE', name: 'Ireland', phoneCode: '+353', flagEmoji: '🇮🇪' },
  { code: 'IL', name: 'Israel', phoneCode: '+972', flagEmoji: '🇮🇱' },
  { code: 'IT', name: 'Italy', phoneCode: '+39', flagEmoji: '🇮🇹' },
  { code: 'CI', name: 'Ivory Coast', phoneCode: '+225', flagEmoji: '🇨🇮' },
  { code: 'JM', name: 'Jamaica', phoneCode: '+1-876', flagEmoji: '🇯🇲' },
  { code: 'JP', name: 'Japan', phoneCode: '+81', flagEmoji: '🇯🇵' },
  { code: 'JO', name: 'Jordan', phoneCode: '+962', flagEmoji: '🇯🇴' },
  { code: 'KZ', name: 'Kazakhstan', phoneCode: '+7', flagEmoji: '🇰🇿' },
  { code: 'KE', name: 'Kenya', phoneCode: '+254', flagEmoji: '🇰🇪' },
  { code: 'KI', name: 'Kiribati', phoneCode: '+686', flagEmoji: '🇰🇮' },
  { code: 'KW', name: 'Kuwait', phoneCode: '+965', flagEmoji: '🇰🇼' },
  { code: 'KG', name: 'Kyrgyzstan', phoneCode: '+996', flagEmoji: '🇰🇬' },
  { code: 'LA', name: 'Laos', phoneCode: '+856', flagEmoji: '🇱🇦' },
  { code: 'LV', name: 'Latvia', phoneCode: '+371', flagEmoji: '🇱🇻' },
  { code: 'LB', name: 'Lebanon', phoneCode: '+961', flagEmoji: '🇱🇧' },
  { code: 'LS', name: 'Lesotho', phoneCode: '+266', flagEmoji: '🇱🇸' },
  { code: 'LR', name: 'Liberia', phoneCode: '+231', flagEmoji: '🇱🇷' },
  { code: 'LY', name: 'Libya', phoneCode: '+218', flagEmoji: '🇱🇾' },
  { code: 'LI', name: 'Liechtenstein', phoneCode: '+423', flagEmoji: '🇱🇮' },
  { code: 'LT', name: 'Lithuania', phoneCode: '+370', flagEmoji: '🇱🇹' },
  { code: 'LU', name: 'Luxembourg', phoneCode: '+352', flagEmoji: '🇱🇺' },
  { code: 'MG', name: 'Madagascar', phoneCode: '+261', flagEmoji: '🇲🇬' },
  { code: 'MW', name: 'Malawi', phoneCode: '+265', flagEmoji: '🇲🇼' },
  { code: 'MY', name: 'Malaysia', phoneCode: '+60', flagEmoji: '🇲🇾' },
  { code: 'MV', name: 'Maldives', phoneCode: '+960', flagEmoji: '🇲🇻' },
  { code: 'ML', name: 'Mali', phoneCode: '+223', flagEmoji: '🇲🇱' },
  { code: 'MT', name: 'Malta', phoneCode: '+356', flagEmoji: '🇲🇹' },
  { code: 'MH', name: 'Marshall Islands', phoneCode: '+692', flagEmoji: '🇲🇭' },
  { code: 'MR', name: 'Mauritania', phoneCode: '+222', flagEmoji: '🇲🇷' },
  { code: 'MU', name: 'Mauritius', phoneCode: '+230', flagEmoji: '🇲🇺' },
  { code: 'MX', name: 'Mexico', phoneCode: '+52', flagEmoji: '🇲🇽' },
  { code: 'FM', name: 'Micronesia', phoneCode: '+691', flagEmoji: '🇫🇲' },
  { code: 'MD', name: 'Moldova', phoneCode: '+373', flagEmoji: '🇲🇩' },
  { code: 'MC', name: 'Monaco', phoneCode: '+377', flagEmoji: '🇲🇨' },
  { code: 'MN', name: 'Mongolia', phoneCode: '+976', flagEmoji: '🇲🇳' },
  { code: 'ME', name: 'Montenegro', phoneCode: '+382', flagEmoji: '🇲🇪' },
  { code: 'MA', name: 'Morocco', phoneCode: '+212', flagEmoji: '🇲🇦' },
  { code: 'MZ', name: 'Mozambique', phoneCode: '+258', flagEmoji: '🇲🇿' },
  { code: 'MM', name: 'Myanmar (Burma)', phoneCode: '+95', flagEmoji: '🇲🇲' },
  { code: 'NA', name: 'Namibia', phoneCode: '+264', flagEmoji: '🇳🇦' },
  { code: 'NR', name: 'Nauru', phoneCode: '+674', flagEmoji: '🇳🇷' },
  { code: 'NP', name: 'Nepal', phoneCode: '+977', flagEmoji: '🇳🇵' },
  { code: 'NL', name: 'Netherlands', phoneCode: '+31', flagEmoji: '🇳🇱' },
  { code: 'NZ', name: 'New Zealand', phoneCode: '+64', flagEmoji: '🇳🇿' },
  { code: 'NI', name: 'Nicaragua', phoneCode: '+505', flagEmoji: '🇳🇮' },
  { code: 'NE', name: 'Niger', phoneCode: '+227', flagEmoji: '🇳🇪' },
  { code: 'NG', name: 'Nigeria', phoneCode: '+234', flagEmoji: '🇳🇬' },
  { code: 'KP', name: 'North Korea', phoneCode: '+850', flagEmoji: '🇰🇵' },
  { code: 'MK', name: 'North Macedonia', phoneCode: '+389', flagEmoji: '🇲🇰' },
  { code: 'NO', name: 'Norway', phoneCode: '+47', flagEmoji: '🇳🇴' },
  { code: 'OM', name: 'Oman', phoneCode: '+968', flagEmoji: '🇴🇲' },
  { code: 'PK', name: 'Pakistan', phoneCode: '+92', flagEmoji: '🇵🇰' },
  { code: 'PW', name: 'Palau', phoneCode: '+680', flagEmoji: '🇵🇼' },
  { code: 'PA', name: 'Panama', phoneCode: '+507', flagEmoji: '🇵🇦' },
  { code: 'PG', name: 'Papua New Guinea', phoneCode: '+675', flagEmoji: '🇵🇬' },
  { code: 'PY', name: 'Paraguay', phoneCode: '+595', flagEmoji: '🇵🇾' },
  { code: 'PE', name: 'Peru', phoneCode: '+51', flagEmoji: '🇵🇪' },
  { code: 'PH', name: 'Philippines', phoneCode: '+63', flagEmoji: '🇵🇭' },
  { code: 'PL', name: 'Poland', phoneCode: '+48', flagEmoji: '🇵🇱' },
  { code: 'PT', name: 'Portugal', phoneCode: '+351', flagEmoji: '🇵🇹' },
  { code: 'QA', name: 'Qatar', phoneCode: '+974', flagEmoji: '🇶🇦' },
  { code: 'RO', name: 'Romania', phoneCode: '+40', flagEmoji: '🇷🇴' },
  { code: 'RU', name: 'Russia', phoneCode: '+7', flagEmoji: '🇷🇺' },
  { code: 'RW', name: 'Rwanda', phoneCode: '+250', flagEmoji: '🇷🇼' },
  { code: 'KN', name: 'Saint Kitts and Nevis', phoneCode: '+1-869', flagEmoji: '🇰🇳' },
  { code: 'LC', name: 'Saint Lucia', phoneCode: '+1-758', flagEmoji: '🇱🇨' },
  { code: 'VC', name: 'Saint Vincent and the Grenadines', phoneCode: '+1-784', flagEmoji: '🇻🇨' },
  { code: 'WS', name: 'Samoa', phoneCode: '+685', flagEmoji: '🇼🇸' },
  { code: 'SM', name: 'San Marino', phoneCode: '+378', flagEmoji: '🇸🇲' },
  { code: 'ST', name: 'Sao Tome and Principe', phoneCode: '+239', flagEmoji: '🇸🇹' },
  { code: 'SA', name: 'Saudi Arabia', phoneCode: '+966', flagEmoji: '🇸🇦' },
  { code: 'SN', name: 'Senegal', phoneCode: '+221', flagEmoji: '🇸🇳' },
  { code: 'RS', name: 'Serbia', phoneCode: '+381', flagEmoji: '🇷🇸' },
  { code: 'SC', name: 'Seychelles', phoneCode: '+248', flagEmoji: '🇸🇨' },
  { code: 'SL', name: 'Sierra Leone', phoneCode: '+232', flagEmoji: '🇸🇱' },
  { code: 'SG', name: 'Singapore', phoneCode: '+65', flagEmoji: '🇸🇬' },
  { code: 'SK', name: 'Slovakia', phoneCode: '+421', flagEmoji: '🇸🇰' },
  { code: 'SI', name: 'Slovenia', phoneCode: '+386', flagEmoji: '🇸🇮' },
  { code: 'SB', name: 'Solomon Islands', phoneCode: '+677', flagEmoji: '🇸🇧' },
  { code: 'SO', name: 'Somalia', phoneCode: '+252', flagEmoji: '🇸🇴' },
  { code: 'ZA', name: 'South Africa', phoneCode: '+27', flagEmoji: '🇿🇦' },
  { code: 'KR', name: 'South Korea', phoneCode: '+82', flagEmoji: '🇰🇷' },
  { code: 'SS', name: 'South Sudan', phoneCode: '+211', flagEmoji: '🇸🇸' },
  { code: 'ES', name: 'Spain', phoneCode: '+34', flagEmoji: '🇪🇸' },
  { code: 'LK', name: 'Sri Lanka', phoneCode: '+94', flagEmoji: '🇱🇰' },
  { code: 'SD', name: 'Sudan', phoneCode: '+249', flagEmoji: '🇸🇩' },
  { code: 'SR', name: 'Suriname', phoneCode: '+597', flagEmoji: '🇸🇷' },
  { code: 'SE', name: 'Sweden', phoneCode: '+46', flagEmoji: '🇸🇪' },
  { code: 'CH', name: 'Switzerland', phoneCode: '+41', flagEmoji: '🇨🇭' },
  { code: 'SY', name: 'Syria', phoneCode: '+963', flagEmoji: '🇸🇾' },
  { code: 'TW', name: 'Taiwan', phoneCode: '+886', flagEmoji: '🇹🇼' },
  { code: 'TJ', name: 'Tajikistan', phoneCode: '+992', flagEmoji: '🇹🇯' },
  { code: 'TZ', name: 'Tanzania', phoneCode: '+255', flagEmoji: '🇹🇿' },
  { code: 'TH', name: 'Thailand', phoneCode: '+66', flagEmoji: '🇹🇭' },
  { code: 'TL', name: 'Timor-Leste', phoneCode: '+670', flagEmoji: '🇹🇱' },
  { code: 'TG', name: 'Togo', phoneCode: '+228', flagEmoji: '🇹🇬' },
  { code: 'TO', name: 'Tonga', phoneCode: '+676', flagEmoji: '🇹🇴' },
  { code: 'TT', name: 'Trinidad and Tobago', phoneCode: '+1-868', flagEmoji: '🇹🇹' },
  { code: 'TN', name: 'Tunisia', phoneCode: '+216', flagEmoji: '🇹🇳' },
  { code: 'TR', name: 'Turkey', phoneCode: '+90', flagEmoji: '🇹🇷' },
  { code: 'TM', name: 'Turkmenistan', phoneCode: '+993', flagEmoji: '🇹🇲' },
  { code: 'TV', name: 'Tuvalu', phoneCode: '+688', flagEmoji: '🇹🇻' },
  { code: 'UG', name: 'Uganda', phoneCode: '+256', flagEmoji: '🇺🇬' },
  { code: 'UA', name: 'Ukraine', phoneCode: '+380', flagEmoji: '🇺🇦' },
  { code: 'AE', name: 'United Arab Emirates', phoneCode: '+971', flagEmoji: '🇦🇪' },
  { code: 'GB', name: 'United Kingdom', phoneCode: '+44', flagEmoji: '🇬🇧' },
  { code: 'US', name: 'United States', phoneCode: '+1', flagEmoji: '🇺🇸' },
  { code: 'UY', name: 'Uruguay', phoneCode: '+598', flagEmoji: '🇺🇾' },
  { code: 'UZ', name: 'Uzbekistan', phoneCode: '+998', flagEmoji: '🇺🇿' },
  { code: 'VU', name: 'Vanuatu', phoneCode: '+678', flagEmoji: '🇻🇺' },
  { code: 'VA', name: 'Vatican City', phoneCode: '+379', flagEmoji: '🇻🇦' },
  { code: 'VE', name: 'Venezuela', phoneCode: '+58', flagEmoji: '🇻🇪' },
  { code: 'VN', name: 'Vietnam', phoneCode: '+84', flagEmoji: '🇻🇳' },
  { code: 'YE', name: 'Yemen', phoneCode: '+967', flagEmoji: '🇾🇪' },
  { code: 'ZM', name: 'Zambia', phoneCode: '+260', flagEmoji: '🇿🇲' },
  { code: 'ZW', name: 'Zimbabwe', phoneCode: '+263', flagEmoji: '🇿🇼' },
];

export { CountryPhone, type CountryPhoneType };
