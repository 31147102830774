import { TipsConstant } from '@meilleursbiens/constants';

export default class TipsUtils {
  static getRandomAgentTip(): string {
    const tips = TipsConstant.AGENTS_TIPS;
    const randomIndex = Math.floor(Math.random() * tips.length);
    return tips[randomIndex];
  }

  static getRandomSellerTip(): string {
    const tips = TipsConstant.SELLERS_TIPS;
    const randomIndex = Math.floor(Math.random() * tips.length);
    return tips[randomIndex];
  }
}
