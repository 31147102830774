import React from 'react';

import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  Text,
  useDisclosure,
} from '@chakra-ui/react';

export interface ModalConfirmProps {
  title?: string;
  description?: string;
  children: (onClick: () => void) => React.ReactNode;
  cancelText?: string;
  confirmText?: string;
  onConfirm: () => void;
  onCancel?: () => void;
  customContent?: React.ReactNode;
}

const ModalConfirm = (props: ModalConfirmProps) => {
  const disclosure = useDisclosure();

  const cancelRef = React.useRef(null);

  return (
    <>
      {props.children(() => disclosure.onOpen())}

      <AlertDialog
        motionPreset="slideInBottom"
        leastDestructiveRef={cancelRef}
        onClose={() => disclosure.onClose()}
        isOpen={disclosure.isOpen}
        isCentered
      >
        <AlertDialogOverlay />

        <AlertDialogContent>
          <AlertDialogHeader>{props.title || 'Êtes-vous sûr ?'}</AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody>
            <Text color={'muted'} fontSize={'sm'} mb={2}>
              {props.description || 'Vous êtes sûr de vous ? Vous ne pouvez pas annuler cette action par la suite.'}
            </Text>
            {props.customContent && props.customContent}
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button
              ref={cancelRef}
              variant={'ghost'}
              onClick={() => {
                disclosure.onClose();
                props.onCancel && props.onCancel();
              }}
            >
              {props.cancelText || 'Annuler'}
            </Button>
            <Button
              variant={'primary'}
              ml={3}
              onClick={() => {
                props.onConfirm();
                disclosure.onClose();
              }}
            >
              {props.confirmText || 'Confirmer'}
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};

export default ModalConfirm;
