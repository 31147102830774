import { keyframes } from '@chakra-ui/react';

const fadeIn = keyframes`
  from {opacity: 0}
  to {opacity: 1}
`;

const fadeOut = keyframes`
  from {opacity: 1}
  to {opacity: 0}
`;

const fadeInUp = keyframes`
  from {opacity: 0; transform: translateY(10px)}
  to {opacity: 1; transform: translateY(0)}
`;

const fadeOutUp = keyframes`
  from {opacity: 1; transform: translateY(0)}
  to {opacity: 0; transform: translateY(10px)}
`;

const fadeInDown = keyframes`
  from {opacity: 0; transform: translateY(-10px)}
  to {opacity: 1; transform: translateY(0)}
`;

const fadeOutDown = keyframes`
  from {opacity: 1; transform: translateY(0)}
  to {opacity: 0; transform: translateY(-10px)}
`;

const fadeInLeft = keyframes`
  from {opacity: 0; transform: translateX(-10px)}
  to {opacity: 1; transform: translateX(0)}
`;

const fadeOutLeft = keyframes`
  from {opacity: 1; transform: translateX(0)}
  to {opacity: 0; transform: translateX(-10px)}
`;

const fadeInRight = keyframes`
  from {opacity: 0; transform: translateX(10px)}
  to {opacity: 1; transform: translateX(0)}
`;

const fadeOutRight = keyframes`
  from {opacity: 1; transform: translateX(0)}
  to {opacity: 0; transform: translateX(10px)}
`;

const fadeInScale = keyframes`
  from {opacity: 0; transform: scale(0.9)}
  to {opacity: 1; transform: scale(1)}
`;

const fadeOutScale = keyframes`
  from {opacity: 1; transform: scale(1)}
  to {opacity: 0; transform: scale(0.9)}
`;

const fadeInRotate = keyframes`
  from {opacity: 0; transform: rotate(10deg)}
  to {opacity: 1; transform: rotate(0)}
`;

const fadeOutRotate = keyframes`
  from {opacity: 1; transform: rotate(0)}
  to {opacity: 0; transform: rotate(10deg)}
`;

const fadeInSkew = keyframes`
  from {opacity: 0; transform: skewX(10deg)}
  to {opacity: 1; transform: skewX(0)}
`;

const fadeOutSkew = keyframes`
  from {opacity: 1; transform: skewX(0)}
  to {opacity: 0; transform: skewX(10deg)}
`;

export {
  fadeIn,
  fadeOut,
  fadeInUp,
  fadeOutUp,
  fadeInDown,
  fadeOutDown,
  fadeInLeft,
  fadeOutLeft,
  fadeInRight,
  fadeOutRight,
  fadeInScale,
  fadeOutScale,
  fadeInRotate,
  fadeOutRotate,
  fadeInSkew,
  fadeOutSkew,
};
