import React, { useEffect, useState } from 'react';

import { Flex, HStack, Slider, SliderFilledTrack, SliderThumb, SliderTrack, Text } from '@chakra-ui/react';

import TextInput, { TextInputProps } from '../TextInput/TextInput';

export interface RangeInputProps extends TextInputProps {
  min: number;
  max: number;
  step?: number;
  formatValue: (value: number) => string | number;
}

const RangeInput = (props: RangeInputProps) => {
  const [isManual, setIsManual] = useState(false);
  const [value, setValue] = useState(props.values[props.name] || 0);

  useEffect(() => {
    if (isManual) {
      setIsManual(false);
      return;
    }

    props.handleChange({
      // @ts-ignore
      target: {
        name: props.name,
        value: value,
      },
    });
  }, [value]);

  useEffect(() => {
    setIsManual(true);
    setValue(props.values[props.name]);
  }, [props.values[props.name]]);

  return (
    <TextInput
      type="hidden"
      customInput={
        <Flex align={'center'} justify={'space-between'} gap={5}>
          <Text fontSize={'sm'} color={'muted'} fontWeight={500}>
            {props.formatValue(value)}
          </Text>
          <Slider
            size={'lg'}
            maxW={'90%'}
            min={props.min}
            max={props.max}
            step={props.step || 1}
            value={value}
            onChange={(e) => setValue(e)}
          >
            <SliderTrack>
              <SliderFilledTrack />
            </SliderTrack>
            <SliderThumb boxSize={5} />
          </Slider>
        </Flex>
      }
      helperText={props.helperText || ''}
      name={props.name + '_hidden'}
      placeholder={''}
      value={props.values[props.name]}
      errors={props.errors}
      touched={props.touched}
      label={props.label}
      isHorizontal={props.isHorizontal}
      values={props.values}
      handleChange={() => {}}
      handleBlur={() => {}}
    />
  );
};

export default RangeInput;
