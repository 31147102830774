import { AuthConstant } from '@meilleursbiens/constants';
import { DevUtils } from '@meilleursbiens/utils';

import Axios, { AxiosError } from 'axios';
import { buildWebStorage, setupCache } from 'axios-cache-interceptor';

import * as Sentry from '@sentry/react';

import { Routes } from '../routes/routes';
import { encryptAxios } from '@meilleursbiens/api';

export default class MBProAPI {
  _axios: any;
  _url: string;
  _headers: object = {};
  _parameters: object = {};
  _needAPIKey: boolean = false;
  _formData: FormData;
  _needCache: boolean = false;
  _cacheID: string;
  _cacheTTL: number = 60;
  _cacheUpdate: object = {};

  /**
   * Create an request easily to the MeilleursBiens API
   *
   * @param url API Endpoint
   * @param needAPIKey
   * @param customUrl
   * @param curl
   */
  constructor(url: string, needAPIKey: boolean = false, customUrl: boolean = false, curl: string = '') {
    this._axios = Axios.create({
      headers: {
        'Content-Type': 'application/json',
      },
    });
    this._axios = setupCache(this._axios, {
      storage: buildWebStorage(sessionStorage, 'mb-cache:'),
      debug: console.log,
      interpretHeader: false,
    });
    //if(DevUtils.isApiUrlLive(false)) encryptAxios(this._axios);
    this._axios.interceptors.response.use(
      (response: any) => {
        try {
          if (response.data.error) {
            if (response.data.message.includes('Votre session a expiré')) {
              window.location.href = Routes.AUTH_REFRESH.url;
            } else if (response.data.message.includes('Vous n\'avez pas accès à cette fonctionnalité')) {
              // On ignore
            }else {
              Sentry.captureException(new Error(response.data.message), {
                extra: response.data,
              });
            }
          }
        } catch (e) {}
        return response;
      },
      (error: AxiosError) => {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          Sentry.captureException(error.response.data);
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          Sentry.captureException(error.message);
        }
        Sentry.captureException(error);
        return Promise.reject(error);
      }
    );
    this._headers = {
      'Content-Type': 'application/json',
    };
    this._parameters = {};
    this._needAPIKey = false;
    this._formData = new FormData();

    let isLocalhost = DevUtils.isDev();

    if (isLocalhost) {
      this._headers['X-MeilleursBiens-Mobile'] = 'true';
    }

    if (url === '') throw new Error('Please specify an url to api endpoint.');
    this._url = DevUtils.getApiURL() + url;
    if (customUrl) this._url = curl;
    this._needAPIKey = needAPIKey;
  }

  /**
   * Create a Request staticly.
   *
   * @param url API endpoint
   * @param needAPIKey
   * @returns {MBProAPI}
   */
  static url(url: string, needAPIKey: boolean = false) {
    return new MBProAPI(url, needAPIKey);
  }

  static customUrl(url: string, needAPIKey: boolean = false) {
    return new MBProAPI(url, needAPIKey, true, url);
  }

  contentJson() {
    this._headers['Content-Type'] = 'application/json';
    return this;
  }

  contentURL() {
    this._headers['Content-Type'] = 'application/x-www-form-urlencoded';
    return this;
  }

  contentFormData() {
    this._headers['Content-Type'] = 'multipart/form-data';
    return this;
  }

  /**
   * Add headers to the request.
   *
   * @param object Request headers in object format.
   */
  headers(object: object) {
    this._headers = { ...this._headers, ...object };
    return this;
  }

  _csrf(csrf: string) {
    this._headers = { ...this._headers, 'X-CSRF-Token': csrf };
    return this;
  }

  needAuth() {
    let jwt = localStorage.getItem(AuthConstant.JWT_TOKEN_KEY);
    let user_id = localStorage.getItem(AuthConstant.MB_ID_KEY);

    this._headers = {
      ...this._headers,
      [AuthConstant.HEADERS.AUTHORIZATION]: 'Bearer ' + jwt,
      [AuthConstant.HEADERS.MB_ID]: user_id,
    };

    return this;
  }

  needRefreshAuth() {
    let jwt = localStorage.getItem(AuthConstant.JWT_TOKEN_KEY);
    let refresh_token = localStorage.getItem(AuthConstant.JWT_REFRESH_TOKEN_KEY);
    let user_id = localStorage.getItem(AuthConstant.MB_ID_KEY);

    this._headers = {
      ...this._headers,
      [AuthConstant.HEADERS.AUTHORIZATION]: 'Bearer ' + jwt,
      [AuthConstant.HEADERS.REFRESH_TOKEN]: 'Bearer ' + refresh_token,
      [AuthConstant.HEADERS.MB_ID]: user_id,
    };

    return this;
  }

  /**
   * Add cache to the request.
   */
  needCache(ttl: number = 60, id: string | null = null, update: object = null) {
    this._needCache = true;

    this._cacheID = id;
    this._cacheTTL = ttl;
    this._cacheUpdate = update;

    return this;
  }

  /**
   * Add parameters to the request.
   *
   * @param object Request headers in object format.
   */
  parameters(object: object) {
    this._parameters = { ...this._parameters, ...object };
    return this;
  }

  formData(formData: FormData) {
    this._formData = formData;
    return this;
  }

  /**
   * Execute post request.
   *
   * @returns {Promise<AxiosResponse<T>>}
   */
  post() {
    let requestOptions: any = {
      headers: { ...this._headers },
    };

    if (!this._needCache) {
      requestOptions.cache = false;
    } else {
      requestOptions.cache = {
        ttl: this._cacheTTL * 1000,
      };

      if (this._cacheID) {
        requestOptions.cache.id = this._cacheID;
      }

      if (this._cacheUpdate) {
        requestOptions.cache.update = this._cacheUpdate;
      }
    }

    return this._axios.post(this._url, JSON.stringify(this._parameters), requestOptions);
  }

  postUpload() {
    let requestOptions: any = {
      headers: { ...this._headers },
    };

    if (!this._needCache) {
      requestOptions.cache = false;
    } else {
      requestOptions.cache = {
        ttl: this._cacheTTL * 1000,
      };

      if (this._cacheID) {
        requestOptions.cache.id = this._cacheID;
      }

      if (this._cacheUpdate) {
        requestOptions.cache.update = this._cacheUpdate;
      }
    }

    return this._axios.post(this._url, this._formData, requestOptions);
  }

  /**
   * Execute post request.
   *
   * @returns {Promise<AxiosResponse<T>>}
   */
  put() {
    let requestOptions: any = {
      headers: { ...this._headers },
    };

    if (!this._needCache) {
      requestOptions.cache = false;
    } else {
      requestOptions.cache = {
        ttl: this._cacheTTL * 1000,
      };

      if (this._cacheID) {
        requestOptions.cache.id = this._cacheID;
      }

      if (this._cacheUpdate) {
        requestOptions.cache.update = this._cacheUpdate;
      }
    }

    return this._axios.put(this._url, JSON.stringify(this._parameters), requestOptions);
  }

  putUpload() {
    let requestOptions: any = {
      headers: { ...this._headers },
    };

    if (!this._needCache) {
      requestOptions.cache = false;
    } else {
      requestOptions.cache = {
        ttl: this._cacheTTL * 1000,
      };

      if (this._cacheID) {
        requestOptions.cache.id = this._cacheID;
      }

      if (this._cacheUpdate) {
        requestOptions.cache.update = this._cacheUpdate;
      }
    }

    return this._axios.put(this._url, this._formData, requestOptions);
  }

  /**
   * Execute patch request.
   *
   * @returns {Promise<AxiosResponse<T>>}
   */
  patch() {
    let requestOptions: any = {
      headers: { ...this._headers },
    };

    if (!this._needCache) {
      requestOptions.cache = false;
    } else {
      requestOptions.cache = {
        ttl: this._cacheTTL * 1000,
      };

      if (this._cacheID) {
        requestOptions.cache.id = this._cacheID;
      }

      if (this._cacheUpdate) {
        requestOptions.cache.update = this._cacheUpdate;
      }
    }

    return this._axios.patch(this._url, JSON.stringify(this._parameters), requestOptions);
  }

  patchUpload() {
    let requestOptions: any = {
      headers: { ...this._headers },
    };

    if (!this._needCache) {
      requestOptions.cache = false;
    } else {
      requestOptions.cache = {
        ttl: this._cacheTTL * 1000,
      };

      if (this._cacheID) {
        requestOptions.cache.id = this._cacheID;
      }

      if (this._cacheUpdate) {
        requestOptions.cache.update = this._cacheUpdate;
      }
    }

    return this._axios.put(this._url, this._formData, requestOptions);
  }

  /**
   * Execute delete request.
   *
   * @returns {Promise<AxiosResponse<T>>}
   */
  delete() {
    let requestOptions: any = {
      headers: { ...this._headers },
    };

    if (!this._needCache) {
      requestOptions.cache = false;
    } else {
      requestOptions.cache = {
        ttl: this._cacheTTL * 1000,
      };

      if (this._cacheID) {
        requestOptions.cache.id = this._cacheID;
      }

      if (this._cacheUpdate) {
        requestOptions.cache.update = this._cacheUpdate;
      }
    }

    return this._axios.delete(this._url, requestOptions);
  }

  /**
   * Execute get request.
   *
   * @returns {Promise<AxiosResponse<T>>}
   */
  get() {
    const params = this._parameters;
    const headers = this._headers;

    let request: any = { params, headers };

    if (!this._needCache) {
      request.cache = false;
    } else {
      request.cache = {
        ttl: this._cacheTTL * 1000,
      };

      if (this._cacheID) {
        request.cache.id = this._cacheID;
      }

      if (this._cacheUpdate) {
        request.cache.update = this._cacheUpdate;
      }
    }

    return this._axios.get(this._url, request);
  }
}
