'use client';

import { createStandaloneToast } from '@chakra-ui/react';

const { toast } = createStandaloneToast();
const position = 'top-right';

export default class ToastUtils {
  static showTest(message: string, customDuration?: number) {
    toast({
      description: message,
      status: 'info',
      duration: this.calculateDuration(message, customDuration),
      position: position,
      isClosable: true,
    });
  }

  static showSuccess(message: string, customDuration?: number) {
    toast({
      description: message,
      status: 'success',
      duration: this.calculateDuration(message, customDuration),
      position: position,
      isClosable: true,
    });
  }

  static showError(message: string, customDuration?: number) {
    toast({
      description: message,
      status: 'error',
      duration: this.calculateDuration(message, customDuration),
      position: position,
      isClosable: true,
    });
  }

  static showDanger(message: string, customDuration?: number) {
    toast({
      description: message,
      status: 'warning',
      duration: this.calculateDuration(message, customDuration),
      position: position,
      isClosable: true,
    });
  }

  static showInfo(message: string, customDuration?: number) {
    toast({
      description: message,
      status: 'info',
      duration: this.calculateDuration(message, customDuration),
      position: position,
      isClosable: true,
    });
  }

  static showInfoWithCustomDuration(message: string, duration: number) {
    toast({
      description: message,
      status: 'info',
      duration: duration,
      position: position,
      isClosable: true,
    });
  }

  static calculateDuration(message: string, customDuration?: number) {
    if (message == null || message.length == 0) {
      return 3000;
    }

    if (customDuration) {
      return customDuration;
    }

    return message.length * 50;
  }
}
