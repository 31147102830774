import React, { useEffect, useState } from 'react';

import {
  Button, Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Skeleton,
  Text
} from '@chakra-ui/react';
import { MBCoreTeamAPI } from '@meilleursbiens/api';
import { MBProTeam } from '@meilleursbiens/types';

import TeamsEditInfoForm from '../../form/TeamsEditInfoForm/TeamsEditInfoForm';

interface MBCoreTeamsEditModalComponentProps {
  teamId: number;
  isOpen: boolean;
  onClose: () => void;
}

export default function MBCoreTeamsEditModalComponent({ teamId, onClose, isOpen }: MBCoreTeamsEditModalComponentProps) {
  const [isLoading, setLoading] = useState(true);
  const [team, setTeam] = useState<MBProTeam | undefined>();

  const _fetchTeam = () => {
    setLoading(true);
    MBCoreTeamAPI.getTeam(teamId)
      .then((response) => {
        setTeam(response.team);
      })
      .catch((e) => console.error(e))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if(isOpen) _fetchTeam();
  }, [isOpen]);

  useEffect(() => {
    _fetchTeam();
  }, [teamId]);

  return (
    <Modal size={'xl'} isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Heading fontSize={'lg'}>Modifier l'équipe / l'agence</Heading>
          <Text fontSize={12} fontWeight={500} color={'muted'}>Vous pouvez personnaliser sur cet onglet votre équipe ou votre agence selon vos goûts</Text>
        </ModalHeader>
        <ModalCloseButton />
        {isLoading || team === undefined ? (
          <ModalBody>
            <Skeleton height={6} mb={2} />
            <Skeleton height={6} mb={2} />
            <Skeleton height={6} mb={2} />
            <Skeleton height={6} mb={2} />
            <Skeleton height={6} mb={2} />
          </ModalBody>
        ) : (
          <ModalBody pt={0}>
            <TeamsEditInfoForm team={team} onTeamUpdated={_fetchTeam} />
          </ModalBody>
        )}
      </ModalContent>
    </Modal>
  );
}
