import React from 'react';
import { useNavigate } from 'react-router-dom';

import { MBProAdminSearchAPI, MBProAdminSearchAPIResponse } from '@meilleursbiens/api';
import { NeoPropertyPicturesUtils } from '@meilleursbiens/neo';
import { Badge, Logo, Persona, PersonaUser } from '@meilleursbiens/ui';
import { ToastUtils } from '@meilleursbiens/utils';

import { UseDisclosureReturn } from '@chakra-ui/hooks/dist/use-disclosure';
import {
  AspectRatio,
  Avatar,
  Box,
  Flex,
  HStack,
  Image,
  Kbd,
  Modal,
  ModalContent,
  ModalOverlay,
  Progress,
  Text,
  Tooltip,
} from '@chakra-ui/react';

import { Command } from 'cmdk';
import { useDebounce } from 'use-debounce';

import { Routes } from '../../../../routes/routes';

export interface AdminSearchCommandBarComponentProps {
  disclosure: UseDisclosureReturn;
}

const INITIAL_RESULT: MBProAdminSearchAPIResponse = {
  properties: [],
  users: [],
  transactions: [],
  transactions_location: [],
  teams: [],
  mandates: [],
  tickets: [],
  estimations: [],
};

export default function AdminSearchCommandBarComponent(props: AdminSearchCommandBarComponentProps) {
  const navigate = useNavigate();

  const inputRef = React.useRef<HTMLInputElement>(null);

  const [isLoading, setIsLoading] = React.useState(false);
  const [result, setResult] = React.useState<MBProAdminSearchAPIResponse>(INITIAL_RESULT);

  const [value, setValue] = React.useState('');
  const [searchValue] = useDebounce(value, 200);

  React.useEffect(() => {
    if (!searchValue) return;
    if (searchValue.length > 2) {
      _fetchAPI(searchValue);
    } else {
      setResult(INITIAL_RESULT);
    }
  }, [searchValue]);

  const _fetchAPI = async (value) => {
    setIsLoading(true);

    MBProAdminSearchAPI.search(value)
      .then((res) => {
        setResult(res);
      })
      .catch((err) => {
        ToastUtils.showError(err);
      })
      .finally(() => setIsLoading(false));
  };

  const _onSelect = (value, isExternal = false) => {
    props.disclosure.onClose();
    if (isExternal) {
      window.open(value, '_blank');
      return;
    } else {
      navigate(Routes.ADMIN_BLANK_SCREEN.url);
      setTimeout(() => {
        navigate(value, { replace: true });
      }, 50);
    }
  };

  const isEmpty =
    result.properties.length === 0 &&
    result.tickets.length === 0 &&
    result.transactions.length === 0 &&
    result.transactions_location.length === 0 &&
    result.teams.length === 0 &&
    result.estimations.length === 0 &&
    result.properties.length === 0 &&
    result.mandates.length === 0;

  return (
    <Modal isOpen={props.disclosure.isOpen} onClose={props.disclosure.onClose}>
      <ModalOverlay />
      <ModalContent>
        <Box className={'mb_ui_cmdk'}>
          <Command shouldFilter={false} loop>
            <Box cmdk-mb-ui-top-shine="" />

            <Command.Input
              ref={inputRef}
              autoFocus
              placeholder="Rechercher sur le back-office..."
              value={value}
              onValueChange={(v) => setValue(v)}
            />

            {!isLoading && <hr cmdk-mb-ui-loader="" />}
            {isLoading && <Progress mt={'12px'} mb={'12px'} h={'2px'} size="xs" isIndeterminate />}

            <Command.List>
              {isEmpty && !isLoading && (
                <Command.Empty>
                  <Text color={'muted'}>Aucun résultat</Text>
                </Command.Empty>
              )}

              {result.users.length > 0 && (
                <Command.Group heading="Utilisateurs">
                  {result.users.map((user) => (
                    <Command.Item
                      key={user.id}
                      value={'user-' + user.id.toString()}
                      onSelect={() => _onSelect(Routes.ADMIN_AGENTS_VIEW.url.replace(':id', user.id.toString()))}
                    >
                      <PersonaUser
                        user={user}
                        secondaryText={user.emailPro || user.email}
                        size={'sm'}
                        nameRightElement={
                          <>
                            {user.status === 0 && (
                              <Badge fontSize={'8px'} colorScheme={'blue'}>
                                Compte en inscription
                              </Badge>
                            )}
                            {user.status === 1 && (
                              <Badge fontSize={'8px'} colorScheme={'green'}>
                                Compte actif
                              </Badge>
                            )}
                            {user.status === 2 && (
                              <Badge fontSize={'8px'} colorScheme={'orange'}>
                                En attente de paiement
                              </Badge>
                            )}
                            {user.status === 3 && (
                              <Badge fontSize={'8px'} colorScheme={'red'}>
                                Compte bloqué
                              </Badge>
                            )}
                            {user.status === 4 && (
                              <Badge fontSize={'8px'} colorScheme={'gray'}>
                                Compte résilié
                              </Badge>
                            )}
                          </>
                        }
                      />
                    </Command.Item>
                  ))}
                </Command.Group>
              )}

              {result.teams.length > 0 && (
                <Command.Group heading="Équipes">
                  {result.teams.map((team) => (
                    <Command.Item
                      key={team.id}
                      value={'team-' + team.id.toString()}
                      onSelect={() => _onSelect(Routes.ADMIN_TEAMS.url.replace(':id', team.id.toString()))}
                    >
                      <Persona avatarUrl={team.image} name={team.name} secondaryText={team.description} size={'sm'} />
                    </Command.Item>
                  ))}
                </Command.Group>
              )}

              {result.properties.length > 0 && (
                <Command.Group heading="Annonces">
                  {result.properties.map((property) => (
                    <Command.Item
                      key={property.id}
                      value={'property-' + property.id.toString()}
                      onSelect={() => _onSelect(Routes.NEO_ADMIN_PROPERTIES_VIEW.url.replace(':id', property.id))}
                    >
                      <Flex>
                        <HStack gap={3}>
                          <Flex
                            position={'relative'}
                            align={'center'}
                            justify={'center'}
                            borderRadius={'md'}
                            width={'90px !important'}
                            height={'55px'}
                          >
                            <AspectRatio w={'full'} height={'full'} ratio={3 / 2}>
                              <Image
                                src={NeoPropertyPicturesUtils.getMainPicture(property, 'compressed')}
                                alt={property.title}
                                objectFit={'cover'}
                                w={'full'}
                                h={'full'}
                                borderRadius={'md'}
                              />
                            </AspectRatio>

                            <Box position={'absolute'} right={1} bottom={1}>
                              <Tooltip label={property.user?.firstName + ' ' + property.user?.name}>
                                <Avatar
                                  borderWidth={2}
                                  borderColor={'white'}
                                  src={property.user?.profile_picture}
                                  name={property.user?.firstName + ' ' + property.user?.name}
                                  size={'xs'}
                                />
                              </Tooltip>
                            </Box>
                          </Flex>
                          <Box>
                            <Text noOfLines={1} fontSize={'xs'} fontWeight={500}>
                              {property.title}
                            </Text>
                            <Text noOfLines={1} color={'muted'} fontSize={10}>
                              {property.address}, {property.city} ({property.postal_code})
                            </Text>
                          </Box>
                        </HStack>
                      </Flex>
                    </Command.Item>
                  ))}
                </Command.Group>
              )}

              {result.transactions.length > 0 && (
                <Command.Group heading="Ventes">
                  {result.transactions.map((transaction) => (
                    <Command.Item
                      key={transaction.id}
                      value={'transaction-' + transaction.id.toString()}
                      onSelect={(value) =>
                        _onSelect(Routes.ADMIN_TRANSACTIONS_VIEW.url.replace(':id', transaction.id.toString()))
                      }
                    >
                      <Flex>
                        <Persona
                          size={'sm'}
                          name={`Transaction #${transaction.id} ${
                            transaction.facture_id ? ' (Facture #' + transaction.facture_id + ')' : ''
                          }`}
                          secondaryText={`${transaction.address?.street || ''}, ${transaction.address?.city || ''} (${
                            transaction.address?.zip || ''
                          })`}
                          nameRightElement={
                            <>
                              {transaction.status == 0 && (
                                <Badge fontSize={'8px'} colorScheme={'cyan'}>
                                  En-cours
                                </Badge>
                              )}
                              {transaction.status == 1 && (
                                <Badge fontSize={'8px'} colorScheme={'yellow'}>
                                  Facturé
                                </Badge>
                              )}
                              {transaction.status == 2 && (
                                <Badge fontSize={'8px'} colorScheme={'green'}>
                                  Signé
                                </Badge>
                              )}
                              {transaction.status == 3 && (
                                <Badge fontSize={'8px'} colorScheme={'gray'}>
                                  Annulé
                                </Badge>
                              )}
                            </>
                          }
                        />
                      </Flex>
                    </Command.Item>
                  ))}
                </Command.Group>
              )}

              {result.transactions_location.length > 0 && (
                <Command.Group heading="Locations">
                  {result.transactions_location.map((transaction) => (
                    <Command.Item
                      key={transaction.id}
                      value={'transaction-' + transaction.id.toString()}
                      onSelect={(value) =>
                        _onSelect(Routes.ADMIN_TRANSACTIONS_LOCATION_VIEW.url.replace(':id', transaction.id.toString()))
                      }
                    >
                      <Flex>
                        <Persona
                          size={'sm'}
                          name={`Transaction #${transaction.id} ${
                            transaction.facture_bailleur
                              ? ' (Facture #' +
                                transaction.facture_bailleur +
                                ' et #' +
                                transaction.facture_locataire +
                                ')'
                              : ''
                          }`}
                          secondaryText={`${transaction.adresse}, ${transaction.city} (${transaction.postal})`}
                          nameRightElement={
                            <>
                              {transaction.status == 0 && (
                                <Badge fontSize={'8px'} colorScheme={'cyan'}>
                                  En-cours
                                </Badge>
                              )}
                              {transaction.status == 1 && (
                                <Badge fontSize={'8px'} colorScheme={'yellow'}>
                                  Facturé
                                </Badge>
                              )}
                              {transaction.status == 2 && (
                                <Badge fontSize={'8px'} colorScheme={'green'}>
                                  Signé
                                </Badge>
                              )}
                              {transaction.status == 3 && (
                                <Badge fontSize={'8px'} colorScheme={'gray'}>
                                  Annulé
                                </Badge>
                              )}
                            </>
                          }
                        />
                      </Flex>
                    </Command.Item>
                  ))}
                </Command.Group>
              )}

              {result.mandates.length > 0 && (
                <Command.Group heading="Mandats">
                  {result.mandates.map((mandate) => (
                    <Command.Item
                      key={mandate.id}
                      value={'mandate-' + mandate.id.toString()}
                      onSelect={(value) => _onSelect(Routes.ADMIN_MANDATES.url + '?id=' + mandate.id)}
                    >
                      <Flex>
                        <HStack gap={3}>
                          <Box>
                            <HStack spacing={2}>
                              {mandate.is_cancelled ? (
                                <Badge fontSize={'8px'} colorScheme={'gray'}>
                                  Cloturé
                                </Badge>
                              ) : (
                                <>
                                  {mandate.is_draft && (
                                    <Badge fontSize={'8px'} colorScheme={'orange'}>
                                      Brouillon
                                    </Badge>
                                  )}
                                  {mandate.is_signed && (
                                    <Badge fontSize={'8px'} colorScheme={'green'}>
                                      Signé
                                    </Badge>
                                  )}
                                  {!mandate.is_draft && !mandate.is_signed ? (
                                    <Badge fontSize={'8px'} colorScheme={'cyan'}>
                                      En attente de signature
                                    </Badge>
                                  ) : (
                                    <></>
                                  )}
                                </>
                              )}
                              <Text noOfLines={1} fontWeight={500} fontSize={'xs'}>
                                Mandat{mandate.is_manual ? ' papier' : ''} #{mandate.id}
                              </Text>
                            </HStack>
                            <Text noOfLines={2} color={'muted'} fontSize={'10px'} mt={1}>
                              {mandate.type.name}
                            </Text>
                          </Box>
                        </HStack>
                      </Flex>
                    </Command.Item>
                  ))}
                </Command.Group>
              )}

              {result.tickets.length > 0 && (
                <Command.Group heading="Tickets">
                  {result.tickets.map((ticket) => (
                    <Command.Item
                      key={ticket.id}
                      value={'ticket-' + ticket.id.toString()}
                      onSelect={(value) => _onSelect(Routes.SUPPORT_ADMIN_PRO_LIST.url + '?ticket=' + ticket.id)}
                    >
                      <Flex>
                        <HStack gap={3}>
                          <Box>
                            <HStack spacing={2}>
                              {ticket.status === 'open' && (
                                <Badge fontSize={'8px'} colorScheme={'green'}>
                                  Ouvert
                                </Badge>
                              )}
                              {ticket.status === 'closed' && (
                                <Badge fontSize={'8px'} colorScheme={'red'}>
                                  Fermé
                                </Badge>
                              )}
                              {ticket.status === 'archived' && (
                                <Badge fontSize={'8px'} colorScheme={'yellow'}>
                                  En attente
                                </Badge>
                              )}
                              <Text noOfLines={1} fontWeight={500} fontSize={'xs'}>
                                [#{ticket.id}] {ticket.title}
                              </Text>
                            </HStack>
                            <Text noOfLines={2} color={'muted'} fontSize={'10px'} mt={1}>
                              {ticket.message}
                            </Text>
                          </Box>
                        </HStack>
                      </Flex>
                    </Command.Item>
                  ))}
                </Command.Group>
              )}
            </Command.List>
            <div cmdk-mb-ui-footer="">
              <Logo />

              <button cmdk-mb-ui-open-trigger="">
                <Text color={'muted'}>Ouvrir la recherche</Text>
                <Kbd>⌘</Kbd> + <Kbd>K</Kbd>
              </button>
            </div>
          </Command>
        </Box>
      </ModalContent>
    </Modal>
  );
}
