import React, { useEffect } from 'react';

import { Button } from '@meilleursbiens/ui';

import { Box, ButtonGroup, Text, useColorMode } from '@chakra-ui/react';
import { ChatBubbleLeftIcon } from '@heroicons/react/24/outline';

import Logo from '../components/Logo';
import LocalStorageUtils from '../utils/LocalStorageUtils';

export default function ErrorScreen({ error, eventId, componentStack, onReset }) {
  const { colorMode } = useColorMode();

  useEffect(() => {
    sessionStorage.clear();
  }, []);

  return (
    <div className="error-screen">
      <Logo isModernWhite={colorMode === 'dark'} isModern={colorMode === 'light'} height={24} />
      <Text fontWeight={600} fontSize={22}>
        Une erreur s'est produite !
      </Text>
      <Text fontSize={12} style={{ maxWidth: 450 }} textAlign={'center'} color={'muted'}>
        Il semble qu'une erreur se soit produite, notre équipe va analyser cette erreur et vous recontacter si besoin.
        Vous pouvez cliquer sur "Retour à l'accueil" juste en dessous pour revenir sur le site.
      </Text>
      <Text fontSize={12} style={{ maxWidth: 450 }} textAlign={'center'} mt={3} color={'muted'}>
        Identifiant de l'erreur : #{eventId}
      </Text>

      <Box
        as={'code'}
        mt={3}
        bg={'gray.800'}
        borderRadius={'md'}
        w={'500px'}
        p={4}
        h={'300px'}
        overflow={'scroll'}
        fontSize={'xs'}
        maxH={'300px'}
      >
        {JSON.stringify(error.toString(), null, 2)}
        {componentStack}
      </Box>
      <ButtonGroup mt={10}>
        <Button
          variant={'primary'}
          cooldown={10000}
          zIndex={100}
          iconColor={'white'}
          boxShadow={'sm'}
          leftIcon={ChatBubbleLeftIcon}
          onClick={() => {
            window.$crisp.push(['do', 'chat:open']);
            window.$crisp.push([
              'do',
              'message:send',
              [
                'text',
                "Bonjour, j'ai rencontré une erreur sur MeilleursBiens. Voici l'erreur : " +
                  error.toString() +
                  ". Je vous joint également l'identifiant de l'erreur pour l'équipe technique : " +
                  eventId,
              ],
            ]);
          }}
        >
          Besoin d'Aide ?
        </Button>
        <Button
          boxShadow={'sm'}
          _hover={{ bg: 'gray.100' }}
          bg={'white'}
          variant={'ghost'}
          zIndex={100}
          onClick={onReset}
        >
          Retour à MeilleursBiens
        </Button>
        <Button
          zIndex={100}
          variant={'outline'}
          color={'white'}
          boxShadow={'sm'}
          onClick={() => {
            LocalStorageUtils.clear();
            setTimeout(() => {
              window.location.reload();
            }, 500);
          }}
        >
          Déconnexion
        </Button>
      </ButtonGroup>
    </div>
  );
}
