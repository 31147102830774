import { cssVar } from '@chakra-ui/styled-system';

const $fs = cssVar('avatar-font-size');

export default {
  baseStyle: {
    container: {
      bg: 'linear-gradient(180deg, #d3d6de 0%, #a8adb5 100%)',
      _dark: {
        bg: 'linear-gradient(180deg, #6e6e6e 0%, #1e2022 100%)',
      },
    },
    label: {
      color: 'white',
      fontSize: $fs.reference,
      fontWeight: 'bold',
    },
  },
  variants: {},
};
