import React from 'react';
// @ts-ignore
import InputMask from 'react-input-mask';

import {
  Box,
  FormControl,
  FormHelperText,
  FormLabel,
  FormLabelProps,
  Input,
  InputGroup,
  InputGroupProps,
  InputProps,
  InputRightAddon,
  InputRightElement,
  SimpleGrid,
  Text,
  TextProps,
  forwardRef,
  useColorModeValue,
} from '@chakra-ui/react';

import { FormikErrors, FormikTouched, FormikValues } from 'formik';

import { TextLinkify } from '../../../Components';

export interface TextInputProps extends InputProps {
  label?: string;
  name: string;
  placeholder?: string;
  helperText?: string;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleBlur: (e: React.FocusEvent<HTMLInputElement>) => void;
  values: FormikValues;
  errors: FormikErrors<any>;
  touched: FormikTouched<any>;
  isHorizontal?: boolean;
  isRequired?: boolean;
  customInput?: React.ReactNode;
  rightElementWidth?: string | number;
  rightElement?: React.ReactNode;
  inputMask?: string;
  inputMaskChar?: string;
  unity?: string;
  labelTextProps?: FormLabelProps;
  inputGroupProps?: InputGroupProps;
  helperTextProps?: TextProps;
}

const TextInput = forwardRef<TextInputProps, 'input'>((props: TextInputProps, ref) => {
  const isTouched = props.touched[props.name] != null;
  const isError = props.errors[props.name] != null && isTouched;
  const hasHelperText = props.helperText && !isError;

  if (props.isHorizontal)
    return (
      <SimpleGrid columns={[1, 2]} spacing={4} position={'relative'}>
        <Box>
          {props.label && (
            <Text fontSize={13} fontWeight={500}>
              {props.label} {props.isRequired && <span style={{ color: '#BB2030' }}>*</span>}
            </Text>
          )}
          {props.helperText && (
            <TextLinkify fontSize={12} fontWeight={400} color={'muted'} {...props.helperTextProps}>
              {props.helperText}
            </TextLinkify>
          )}
        </Box>
        <Box>
          <InputGroup {...props.inputGroupProps}>
            {props.inputMask ? (
              <Input
                ref={ref}
                as={InputMask}
                mask={props.inputMask}
                maskChar={props.inputMaskChar || null}
                type="text"
                autoComplete="off"
                {...props}
                name={props.name}
                placeholder={props.placeholder || ''}
                onChange={props.handleChange}
                onBlur={props.handleBlur}
                value={props.values[props.name]}
                isInvalid={isError || false}
              />
            ) : (
              <Input
                ref={ref}
                type="text"
                autoComplete="off"
                placeholder={props.placeholder || ''}
                onChange={props.handleChange}
                onBlur={props.handleBlur}
                value={props.values[props.name]}
                isInvalid={isError || false}
                {...props}
                name={props.name}
              />
            )}
            {props.rightElement && (
              <InputRightElement width={props.rightElementWidth || '4.5rem'}>{props.rightElement}</InputRightElement>
            )}
            {props.unity && (
              <InputRightAddon
                fontSize={12}
                color={'muted'}
                boxShadow={'base'}
                bg={useColorModeValue('white', 'gray.800')}
                children={props.unity}
              />
            )}
          </InputGroup>
          {props.customInput && props.customInput}
          {isError && (
            <Text fontSize={12} color={useColorModeValue('red.600', 'red.300')} mt={1}>
              {/* @ts-ignore */}
              {props.errors[props.name]}
            </Text>
          )}
        </Box>
      </SimpleGrid>
    );

  return (
    <FormControl position={'relative'}>
      {props.label && (
        <FormLabel fontSize={12} fontWeight={500} {...props.labelTextProps}>
          {props.label} {props.isRequired && <span style={{ color: '#BB2030' }}>*</span>}
        </FormLabel>
      )}
      <InputGroup {...props.inputGroupProps}>
        {props.inputMask ? (
          <Input
            ref={ref}
            as={InputMask}
            mask={props.inputMask}
            maskChar={props.inputMaskChar || null}
            type="text"
            autoComplete="off"
            placeholder={props.placeholder || ''}
            onChange={props.handleChange}
            onBlur={props.handleBlur}
            value={props.values[props.name]}
            isInvalid={isError || false}
            {...props}
            name={props.name}
          />
        ) : (
          <Input
            ref={ref}
            type="text"
            autoComplete="off"
            placeholder={props.placeholder || ''}
            onChange={props.handleChange}
            onBlur={props.handleBlur}
            value={props.values[props.name]}
            isInvalid={isError || false}
            {...props}
            name={props.name}
          />
        )}
        {props.rightElement && (
          <InputRightElement width={props.rightElementWidth || '4.5rem'}>{props.rightElement}</InputRightElement>
        )}
        {props.unity && (
          <InputRightAddon
            fontSize={12}
            color={'muted'}
            boxShadow={'sm'}
            bg={useColorModeValue('white', 'gray.800')}
            children={props.unity}
          />
        )}
      </InputGroup>
      {props.customInput && props.customInput}
      {isError && (
        <FormHelperText fontSize={11} color={'red.600'}>
          {/* @ts-ignore */}
          {props.errors[props.name]}
        </FormHelperText>
      )}
      {hasHelperText && (
        <TextLinkify fontSize={11} mt={1} color={'muted'} {...props.helperTextProps}>
          {props.helperText}
        </TextLinkify>
      )}
    </FormControl>
  );
});

export default TextInput;
