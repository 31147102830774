import React from 'react';

import { Td } from '@chakra-ui/react';

import { Row, flexRender } from '@tanstack/react-table';

const TableApiMemoizedCell = React.memo(({ row }: { row: Row<any> }) => {
  return (
    <>
      {row.getVisibleCells().map((cell) => {
        const meta: any = cell.column.columnDef.meta;
        return (
          <Td key={cell.id} isNumeric={meta?.isNumeric}>
            {flexRender(cell.column.columnDef.cell, cell.getContext())}
          </Td>
        );
      })}
    </>
  );
});

export default TableApiMemoizedCell;
