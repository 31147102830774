import React, { useEffect } from 'react';

import moment from 'moment';
import { useDebounce } from 'use-debounce';
import { useDebounceValue } from 'usehooks-ts';

import TextInput, { TextInputProps } from '../TextInput/TextInput';

export interface DateInputProps extends TextInputProps {
  maxDate?: string;
  minDate?: string;
  format?: string;
}

const _transpileDateFormatToInputDate = (date: string, format: string) => {
  const dateParsed = moment(date, format);
  if (dateParsed.isValid()) {
    return dateParsed.format('YYYY-MM-DD');
  } else {
    return '';
  }
};

const _transpileInputDateToDateFormat = (date: string, format: string) => {
  const dateParsed = moment(date, 'YYYY-MM-DD');
  if (dateParsed.isValid()) {
    return dateParsed.format(format);
  } else {
    return '';
  }
};

const DateInput = (props: DateInputProps) => {
  const [value, setValue] = React.useState<string>('');

  const [inputValue, setInputValue] = React.useState(
    _transpileDateFormatToInputDate(props.values[props.name], props.format || 'DD/MM/YYYY')
  );

  useEffect(() => {
    setValue(_transpileDateFormatToInputDate(props.values[props.name], props.format || 'DD/MM/YYYY'));
    setInputValue(_transpileDateFormatToInputDate(props.values[props.name], props.format || 'DD/MM/YYYY'));
  }, [props.values[props.name]]);

  useEffect(() => {
    props.handleChange({
      // @ts-ignore
      target: {
        name: props.name,
        value: _transpileInputDateToDateFormat(inputValue, props.format || 'DD/MM/YYYY'),
      },
    });
  }, [value]);

  const minDate = _transpileDateFormatToInputDate(props.minDate || '', props.format || 'DD/MM/YYYY');
  const maxDate = _transpileDateFormatToInputDate(props.maxDate || '', props.format || 'DD/MM/YYYY');

  return (
    <TextInput
      {...props}
      {...(props.minDate && { min: minDate })}
      {...(props.maxDate && { max: maxDate })}
      type={'date'}
      defaultValue={inputValue}
      value={inputValue}
      handleChange={(e) => setInputValue(e.target.value)}
      handleBlur={(e) => setValue(e.target.value)}
    />
  );
};

export default DateInput;
