import {
  AcademicCapIcon,
  ArrowsRightLeftIcon,
  BanknotesIcon,
  BookOpenIcon,
  CameraIcon,
  CreditCardIcon as CashIcon,
  ChartBarIcon,
  ChatBubbleLeftIcon as ChatIcon,
  CodeBracketSquareIcon,
  CogIcon,
  RectangleStackIcon as CollectionIcon,
  CreditCardIcon,
  CubeTransparentIcon,
  CurrencyEuroIcon,
  CursorArrowRippleIcon as CursorClickIcon,
  DocumentIcon,
  DocumentChartBarIcon as DocumentReportIcon,
  DocumentMagnifyingGlassIcon as DocumentSearchIcon,
  DocumentTextIcon,
  FolderOpenIcon,
  GlobeAltIcon,
  HomeIcon,
  BuildingOffice2Icon as OfficeBuildingIcon,
  RectangleGroupIcon,
  ScaleIcon,
  ShoppingCartIcon,
  SparklesIcon,
  Squares2X2Icon as TemplateIcon,
  UserCircleIcon,
  UserGroupIcon,
  UserPlusIcon,
  VariableIcon,
} from '@heroicons/react/24/outline';

interface SectionLink {
  id: string;
  label: string;
  subLabel: string;
  feature?: string;
  icon: any;
  href?: string;
  permission: string[];
}

interface SectionCategory {
  id: string;
  label: string;
  feature?: string;
  icon: any;
  href?: string;
  collapseByDefault?: boolean;
  children?: SectionLink[];
  permission?: string[];
  badge?: string;
}

const ADMIN_NAVIGATION: SectionCategory[] = [
  {
    id: 'admin_stats_dashboard',
    label: 'Tableau de Bord',
    icon: RectangleGroupIcon,
    href: '/admin/dashboard',
    permission: ['back.office.dashboard'],
  },
  {
    id: 'admin_history',
    label: "Fil d'activité",
    icon: BookOpenIcon,
    href: '/admin/logs',
    permission: ['back.office.logs.list'],
  },
  {
    id: 'admin_agents',
    label: 'Utilisateurs',
    icon: UserCircleIcon,
    href: '/admin/agents',
    permission: ['back.office.users.list'],
  },
  {
    id: 'admin_teams',
    label: 'Équipes',
    icon: UserGroupIcon,
    href: '/admin/core/teams',
    permission: ['back.office.teams.list'],
  },
  {
    id: 'admin_estimation',
    label: 'Estimation',
    feature: 'mb_estimation',
    icon: CubeTransparentIcon,
    collapseByDefault: false,
    href: '/admin/estimations',
    children: [
      {
        id: 'admin_estimations',
        label: 'Estimations',
        subLabel: 'Toutes les estimations des agents.',
        icon: CubeTransparentIcon,
        href: '/admin/estimations',
        permission: ['back.office.estimations.list'],
      },
      {
        id: 'admin_leads',
        label: 'Prospects',
        feature: 'mb_estimation_leads',
        subLabel: 'Toutes les leads envoyés aux agents.',
        icon: CursorClickIcon,
        href: '/admin/estimations/leads',
        permission: ['back.office.leads.list'],
      },
    ],
  },
  {
    id: 'admin_diffusion',
    label: 'Diffusion',
    feature: 'mb_neo_properties',
    icon: GlobeAltIcon,
    collapseByDefault: false,
    href: '/admin/neo/properties',
    children: [
      {
        id: 'admin_properties',
        label: 'Annonces',
        subLabel: 'Tous les biens en diffusion.',
        feature: 'mb_neo_properties',
        icon: TemplateIcon,
        href: '/admin/neo/properties',
        permission: ['back.office.neo.properties.list'],
      },
      {
        id: 'admin_platforms',
        label: 'Plateformes',
        subLabel: 'Toutes les plateformes de diffusion.',
        feature: 'mb_neo_properties',
        icon: CollectionIcon,
        href: '/admin/neo/platforms',
        permission: ['back.office.neo.platforms.list'],
      },
    ],
  },
  {
    id: 'transactions',
    label: 'Transactions',
    feature: 'mb_transactions',
    icon: ArrowsRightLeftIcon,
    collapseByDefault: false,
    href: '/admin/transactions/dashboard',
    children: [
      {
        id: 'admin_transactions_dashboard',
        label: 'Tableau de Bord',
        subLabel: 'Toutes les transactions de ventes et de locations à régler.',
        icon: ChartBarIcon,
        href: '/admin/transactions/dashboard',
        permission: ['back.office.transactions.list'],
      },
      {
        id: 'admin_transactions_ventes',
        label: 'Ventes',
        subLabel: 'Toutes les transactions de ventes.',
        icon: HomeIcon,
        href: '/admin/transactions',
        permission: ['back.office.transactions.list'],
      },
      {
        id: 'admin_transactions_locations',
        label: 'Locations',
        subLabel: 'Toutes les transactions de locations.',
        icon: OfficeBuildingIcon,
        href: '/admin/transactions-location',
        permission: ['back.office.transactions.list'],
      },
    ],
  },
  {
    id: 'network',
    label: 'Réseau',
    icon: UserGroupIcon,
    collapseByDefault: false,
    href: '/admin/network',
    children: [
      {
        id: 'admin_contacts',
        label: 'Contacts',
        subLabel: 'Liste des propsects du réseau.',
        feature: 'mb_contacts',
        icon: CollectionIcon,
        href: '/admin/contacts',
        permission: ['back.office.contacts.list'],
      },
      {
        id: 'admin_marketplace',
        label: 'Marketplace',
        subLabel: 'Les offres de la Marketplace',
        feature: 'mb_pro_marketplace',
        icon: ShoppingCartIcon,
        href: '/admin/marketplace',
        permission: ['back.office.marketplace.list'],
      },
    ],
  },
  {
    id: 'comptabilite-juridique',
    label: 'Comptabilité & Juridique',
    icon: CreditCardIcon,
    collapseByDefault: false,
    href: '/admin/network',
    children: [
      {
        id: 'admin_comptabilites',
        label: 'Factures du réseau',
        subLabel: 'Toutes les factures du réseau.',
        icon: DocumentIcon,
        href: '/admin/invoices',
        permission: ['back.office.accounting.invoice.list'],
      },
      {
        id: 'admin_proabono_invoices',
        label: "Factures d'abonnements",
        subLabel: 'Gérer les factures des agents sur ProAbono',
        feature: 'mb_subscription',
        icon: CurrencyEuroIcon,
        href: '/admin/subscriptions/invoices',
        permission: ['back.office.subscription.view'],
      },
      {
        id: 'admin_overdues',
        label: 'Litiges & Contentieux',
        subLabel: 'Gestion des litiges et contientieux.',
        icon: ScaleIcon,
        href: '/admin/overdues',
        permission: ['back.office.users.overdues.list'],
      },
      {
        id: 'admin_contracts',
        label: 'Contrat du réseau',
        subLabel: "Versions du contrat d'agent mandataire.",
        icon: DocumentSearchIcon,
        href: '/admin/contrats',
        permission: ['back.office.contracts.list'],
      },
    ],
  },
  {
    id: 'documents',
    label: 'Documents',
    icon: FolderOpenIcon,
    collapseByDefault: false,
    href: '/admin/documents',
    children: [
      {
        id: 'admin_documents',
        label: 'Documents',
        subLabel: 'Tout les documents du réseau.',
        feature: 'mb_documents',
        icon: FolderOpenIcon,
        href: '/admin/documents',
        permission: ['back.office.documents.list'],
      },
      {
        id: 'admin_mandats',
        label: 'Mandats',
        subLabel: 'Mandats et DPE des Agents.',
        icon: DocumentReportIcon,
        href: '/admin/mandates',
        permission: ['back.office.mandats.list'],
      },
    ],
  },
  {
    id: 'harp',
    label: 'Ressources Humaines',
    icon: UserGroupIcon,
    collapseByDefault: false,
    children: [
      {
        id: 'admin_harp_salaries',
        label: 'Gestion des salaires',
        subLabel: 'Gérer les salaires du personnel',
        feature: 'mb_subscription',
        icon: CreditCardIcon,
        href: '/admin/harp/salaries',
        permission: ['back.office.harp.salaries'],
      },
    ],
  },
  {
    id: 'videos',
    label: 'Formations',
    icon: AcademicCapIcon,
    collapseByDefault: false,
    href: '/admin/formations',
    permission: ['ADMIN_FORMATIONS_LIST'],
    children: [
      {
        id: 'admin_formations',
        label: 'Formations',
        subLabel: 'Les formations MeilleursBiens',
        feature: 'mb_formations',
        icon: AcademicCapIcon,
        href: '/admin/formations',
        permission: ['back.office.formation.list'],
      },
      {
        id: 'admin_replays',
        label: 'Replays',
        subLabel: 'Liste des replays',
        feature: 'mb_replays',
        icon: CameraIcon,
        href: '/admin/replays',
        permission: ['back.office.replays.list'],
      },
    ],
  },
  {
    id: 'proabono',
    label: 'Abonnements',
    icon: BanknotesIcon,
    collapseByDefault: false,
    children: [
      {
        id: 'admin_subscriptions',
        label: 'Abonnements',
        subLabel: 'Gérer les abonnements des agents sur ProAbono',
        feature: 'mb_subscription',
        icon: CashIcon,
        href: '/admin/subscriptions',
        permission: ['back.office.subscription.view'],
      },
      {
        id: 'admin_proabono_features',
        label: 'Fonctionnalités',
        subLabel: 'Gérer les fonctionnalités des agents sur ProAbono',
        feature: 'mb_subscription',
        icon: SparklesIcon,
        href: '/admin/subscriptions/features',
        permission: ['back.office.subscription.view'],
      },
    ],
  },
  {
    id: 'saas',
    label: 'Développeur',
    icon: CodeBracketSquareIcon,
    collapseByDefault: false,
    children: [
      {
        id: 'admin_permissions_roles',
        label: 'Roles',
        subLabel: 'Gérer les rôles et permissions',
        icon: UserPlusIcon,
        href: '/admin/core/permissions/roles',
        permission: ['back.office.permissions'],
      },
      {
        id: 'admin_sms',
        label: 'SMS',
        subLabel: 'Gérer les modèles de SMS et envoyer des SMS',
        icon: ChatIcon,
        href: '/admin/core/sms',
        permission: ['back.office.sms-templates.list'],
      },
      {
        id: 'admin_forms',
        label: 'Formulaires',
        subLabel: 'Les offres de la Marketplace',
        icon: VariableIcon,
        href: '/admin/saas/form-schemas',
        permission: ['back.office.saas.form-builder.list'],
      },
      {
        id: 'admin_tools',
        label: 'Outils',
        subLabel: 'Outils SaaS',
        icon: CogIcon,
        href: '/admin/saas/tools',
        permission: [],
      },
    ],
  },
];

export default ADMIN_NAVIGATION;
