import React, { useState } from 'react';

import {
  Box,
  Button,
  Flex,
  Popover,
  PopoverAnchor,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Text,
  useDisclosure,
} from '@chakra-ui/react';

export default function () {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [stepIndex, setStepIndex] = useState(0);

  const steps = [
    {
      target: '#step1',
      content: 'This is the first step of the onboarding tour.',
    },
    {
      target: '#step2',
      content: 'This is the second step of the onboarding tour.',
    },
    {
      target: '#step3',
      content: 'This is the third step of the onboarding tour.',
    },
  ];

  const handleNext = () => {
    if (stepIndex < steps.length - 1) {
      setStepIndex(stepIndex + 1);
      scrollToTarget(steps[stepIndex + 1].target);
    } else {
      onClose();
    }
  };

  const handlePrev = () => {
    if (stepIndex > 0) {
      setStepIndex(stepIndex - 1);
    }
  };

  const scrollToTarget = (target: string) => {
    const element = document.querySelector(target);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <Popover isOpen={isOpen} onOpen={onOpen} onClose={onClose} closeOnBlur={false} isLazy lazyBehavior="keepMounted">
      <PopoverAnchor>
        <Button>Open Tour</Button>
      </PopoverAnchor>

      <Portal>
        <PopoverContent>
          <PopoverBody></PopoverBody>
        </PopoverContent>
      </Portal>
    </Popover>
  );
}
