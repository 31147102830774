import { defineStyle, defineStyleConfig } from '@chakra-ui/styled-system';

const variantSolid = defineStyle((props) => {
  const { colorScheme: c } = props;

  const color = `${c}.500`;
  const hoverColor = `${c}.600`;

  return {
    borderWidth: '1px',
    borderColor: color,
    boxShadow: 'xs',
    bg: color,
    color: 'white',
    _hover: {
      borderColor: hoverColor,
      bg: hoverColor,
    },
    _focus: {
      boxShadow: '0px 0px 0px 2px #ffd5da',
    },
    _dark: {
      boxShadow: 'dark-xs',

      _focus: {
        boxShadow: '0px 0px 0px 2px #6e383e',
      },
    },
  };
});

const variantGhost = defineStyle((props) => {
  return {
    _checked: {
      bg: 'blackAlpha.100',
    },
    _dark: {
      _checked: {
        bg: 'whiteAlpha.100',
      },
    },
  };
});

const variantOutline = defineStyle((props) => {
  const { colorScheme: c } = props;

  return {
    color: `${c}.500`,
    _hover: {
      color: `${c}.400`,
      bg: 'blackAlpha.50',
    },
    _dark: {
      color: `${c}.500`,
      _hover: {
        color: `${c}.400`,
        bg: 'whiteAlpha.50',
      },
    },
  };
});

const variantPrimary = defineStyle((props) => {
  return variantSolid({
    ...props,
    colorScheme: 'primary',
    bg: 'linear-gradient(0deg, #fff 0%, #ca0f0f 100%)',
  });
});

const variantSecondary = defineStyle({
  borderWidth: '1px',
  borderColor: 'gray.300',
  boxShadow: 'xs',
  bg: 'white',
  color: 'inherit',
  _hover: {
    borderColor: 'gray.200',
    bg: 'gray.50',
  },
  _focus: {
    boxShadow: '0px 0px 0px 2px #f5f3f3',
  },
  _dark: {
    boxShadow: 'dark-xs',
    borderColor: 'gray.600',
    bg: 'gray.700',
    _hover: {
      borderColor: 'gray.500',
      bg: 'gray.600',
    },
    _focus: {
      boxShadow: '0px 0px 0px 2px #3b3e41',
    },
  },
});

const variantTertiary = defineStyle({
  borderWidth: '1px',
  borderColor: 'red.50',
  bg: 'red.50',
  color: 'red.500',
  _hover: {
    borderColor: 'red.50',
  },
  _focus: {
    boxShadow: '0px 0px 0px 2px #eedfe0',
  },
  _dark: {
    borderColor: 'red.700',
    color: 'red.500',
    _hover: {
      borderColor: 'gray.600',
    },
    _focus: {
      boxShadow: '0px 0px 0px 2px #eedfe0',
    },
  },
});

const sizes = {
  xl: defineStyle({
    h: '2.7rem',
    minW: '2.8rem',
    fontSize: '0.9rem',
    px: '5',
  }),
  lg: defineStyle({
    h: '2.5rem',
    minW: '2.6rem',
    fontSize: '0.85rem',
    px: '4',
  }),
  md: defineStyle({
    h: '2.1rem',
    minW: '2.5rem',
    fontSize: '0.8rem',
    px: '3',
  }),
  sm: defineStyle({
    h: '1.90rem',
    minW: '2.3rem',
    fontSize: '0.78rem',
    px: '2',
  }),
  xs: defineStyle({
    h: '1.65rem',
    minW: '2.2rem',
    fontSize: '0.7rem',
    px: '2',
  }),
};

export default defineStyleConfig({
  defaultProps: {
    size: 'md',
    variant: 'secondary',
  },
  baseStyle: {
    fontWeight: 600,
    borderRadius: '0.4rem',
    transition: 'all 0.05s ease-in-out',
    _active: {
      transform: 'scale(0.99)',
    },
  },
  variants: {
    solid: variantSolid,
    outline: variantOutline,
    ghost: variantGhost,
    primary: variantPrimary,
    secondary: variantSecondary,
    tertiary: variantTertiary,
  },
  sizes,
});
