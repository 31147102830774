import React, { useEffect, useState } from 'react';

import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import { MBCoreTeamAPI } from '@meilleursbiens/api';
import { MBProTeam } from '@meilleursbiens/types';

import TeamsInvitationForm from '../../form/TeamsInvitationForm/TeamsInvitationForm';

interface MBCoreTeamsInviteModalComponentProps {
  teamId: number;
  isOpen: boolean;
  onClose: () => void;
}

export default function MBCoreTeamsInviteModalComponent({
  teamId,
  onClose,
  isOpen,
}: MBCoreTeamsInviteModalComponentProps) {
  const [team, setTeam] = useState<MBProTeam | undefined>();

  const _fetchTeam = () => {
    MBCoreTeamAPI.getTeam(teamId)
      .then((response) => {
        setTeam(response.team);
      })
      .catch((e) => console.error(e));
  };

  useEffect(() => {
    if(isOpen) _fetchTeam();
  }, [isOpen]);

  useEffect(() => {
    _fetchTeam();
  }, [teamId]);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Gérer les membres</ModalHeader>
        <ModalCloseButton />
        <ModalBody>{team !== undefined && <TeamsInvitationForm team={team} onTeamUpdated={_fetchTeam} />}</ModalBody>

        <ModalFooter mt={4}>
          <Button variant="ghost" mr={3} onClick={onClose}>
            Fermer
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
