import { ServicesConstant } from '@meilleursbiens/constants';

export default class DevUtils {
  static isDev(): boolean {
    return (
      window.location.hostname === 'localhost' ||
      window.location.hostname === '127.0.0.1' ||
      window.location.hostname === 'staging-app.meilleursbiens.com'
    );
  }

  static isLocal(): boolean {
    return window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1';
  }

  static getApiURL(isSSR = false): string {
    if (isSSR) {
      // @ts-ignore
      const env = process.env.NODE_ENV;
      if (env === 'development') {
        return ServicesConstant.MEILLEURSBIENS.API_URL_DEMO;
      }

      // todo modifier
      return ServicesConstant.MEILLEURSBIENS.API_URL_LIVE;
    }

    const apiURL = localStorage.getItem('mb_api_url');
    if (apiURL) {
      return apiURL.replace(/['"]+/g, '');
    }

    return ServicesConstant.MEILLEURSBIENS.API_URL_LIVE;
  }

  static isApiUrlLive(isSSR = false): boolean {
    if (isSSR) {
      // @ts-ignore
      const env = process.env.NODE_ENV;
      if (env === 'development') {
        return false;
      }

      return true;
    }

    const apiURL = localStorage.getItem('mb_api_url');
    if (apiURL) {
      if(apiURL.includes('staging')) {
        return false;
      }
    }

    return true;
  }

  static getClientUrl(): string {
    return DevUtils.isDev() ? 'http://localhost:4200' : 'https://my.meilleursbiens.com';
  }

  static getRecaptchaKey(): string {
    const isDev = DevUtils.isDev();
    return isDev ? ServicesConstant.GOOGLE.RECAPTCHA_STAGING : ServicesConstant.GOOGLE.RECAPTCHA_PROD;
  }

  static waitPromise(delay: number): Promise<void> {
    return new Promise(function (resolve) {
      setTimeout(resolve, delay);
    });
  }
}
