import { MBProClient, MBProSidebarCounts } from '@meilleursbiens/types';

import { MBApi } from '../../../../index';

export default class MBProDashboardAPI {
  static getSidebarCounts() {
    return MBApi.url('/v1/mbpro/sidebar/counts')
      .needAuth()
      .needCache(2, 'MBProDashboardAPI.getSidebarCounts')
      .get<MBProSidebarCounts>();
  }
}
