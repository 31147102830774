const SELLERS_TIPS = [
  'Un bien au prix du marché se vend en moyenne deux fois plus rapidement qu’un bien surévalué.',
  'De belles photos peuvent augmenter les visites de votre bien de 50 %.',
  'Un home staging peut augmenter la valeur perçue de votre bien de 10 %.',
  'Le diagnostic de performance énergétique (DPE) est obligatoire avant de vendre.',
  'Une annonce bien rédigée attire jusqu’à 70 % de contacts en plus.',
  'Les biens rénovés ont plus de chances de se vendre rapidement.',
  'Il est possible de vendre en viager tout en continuant à vivre dans le bien.',
  'Proposer une visite virtuelle peut réduire les délais de vente de moitié.',
  'Réaliser les petites réparations avant de vendre peut éviter de négocier à la baisse.',
  "Les frais d'agence sont déductibles de vos impôts en cas de revente locative.",
  "Un bailleur peut demander jusqu'à deux mois de dépôt de garantie pour un logement meublé.",
  'Proposer un logement meublé peut augmenter vos loyers de 20 %.',
  'Les travaux d’entretien sont à la charge du bailleur, pas du locataire.',
  'Vous pouvez bénéficier d’avantages fiscaux avec la loi Pinel.',
  'Louer en courte durée peut générer jusqu’à 50 % de revenus supplémentaires.',
  'Un état des lieux précis peut vous protéger en cas de litige avec le locataire.',
  'Les assurances loyers impayés couvrent jusqu’à 24 mois d’arriérés.',
  'Louer à un étudiant peut garantir des paiements stables grâce à la caution parentale.',
  'Vous pouvez proposer une clause de révision annuelle du loyer dans votre bail.',
  'Les charges récupérables doivent être clairement mentionnées dans le bail.',
  'Visiter un bien à différents moments de la journée peut révéler des surprises (lumière, bruit).',
  'Il est toujours possible de négocier le prix affiché d’un bien immobilier.',
  'Les frais de notaire représentent environ 7 % pour un achat dans l’ancien.',
  'Faire appel à un courtier peut réduire vos coûts d’emprunt jusqu’à 0,5 %.',
  'Les biens en rez-de-chaussée sont généralement 15 % moins chers que les étages supérieurs.',
  'Vérifiez toujours la conformité des diagnostics obligatoires avant d’acheter.',
  'Un bien à rénover peut coûter moins cher à l’achat mais nécessite un budget travaux.',
  'Acheter un bien en copropriété implique de lire attentivement les procès-verbaux des assemblées générales.',
  "Une simulation de prêt vous donne une idée claire de votre capacité d'achat avant de visiter.",
  'Un agent immobilier connaît souvent des biens en avant-première, avant leur mise en ligne.',
];

const AGENTS_TIPS = [
  'Un mandat papier peut être créé rapidement grâce à notre tutoriel dédié.',
  'Proposer une visite virtuelle permet de réduire considérablement les délais de vente.',
  'Une annonce bien rédigée attire jusqu’à 70 % de contacts en plus.',
  'Le diagnostic de performance énergétique (DPE) est obligatoire pour signer un mandat.',
  'Les outils d’estimation immobilière sont disponibles dans votre espace pour convaincre vos clients.',
  'Les signatures électroniques sont valides juridiquement et accélèrent vos transactions.',
  'Un home staging augmente la valeur perçue d’un bien de 10 % en moyenne.',
  'Les diagnostics obligatoires doivent être fournis avant la publication de l’annonce.',
  'Les biens exclusifs ont 30 % plus de chances de se vendre rapidement.',
  'Un état des lieux détaillé protège le propriétaire et le locataire en cas de litige.',
  'Utiliser des photos professionnelles peut doubler les demandes de visites.',
  'Les mandats électroniques se valident automatiquement après signature.',
  'Les biens rénovés ont plus de chances de séduire les acheteurs dès la première visite.',
  'Une bonne stratégie de communication renforce votre crédibilité auprès des clients.',
  'L’utilisation des réseaux sociaux pour promouvoir vos annonces augmente leur visibilité.',
  'Les vidéos de présentation attirent plus de prospects sérieux qu’une simple annonce texte.',
  'Renseigner précisément les caractéristiques d’un bien améliore sa position sur les portails.',
  'Envoyer des newsletters régulières peut fidéliser vos prospects.',
  "Les frais de notaire dans l'ancien représentent environ 7 % du prix de vente.",
  'Accompagner vos clients dans les démarches administratives renforce la satisfaction client.',
  'Proposer un bien à prix réaliste augmente les chances de vendre rapidement.',
  'Les biens proches des transports publics sont généralement plus attractifs.',
  'Mettre en avant les atouts uniques d’un bien fait la différence auprès des acheteurs.',
  'Une veille sur le marché local permet d’adapter vos stratégies rapidement.',
  "Les mandats exclusifs sont souvent mieux valorisés sur les portails d'annonces.",
  'Participer à des formations régulières améliore vos compétences et votre efficacité.',
  'Les outils d’intelligence artificielle peuvent vous aider à rédiger des annonces percutantes.',
  'Les recommandations clients sont un excellent moyen de renforcer votre réputation.',
  'Organiser des portes ouvertes peut accélérer la vente d’un bien.',
  'Toujours vérifier la conformité des documents avant de signer un mandat.',
];

export default {
  SELLERS_TIPS,
  AGENTS_TIPS,
};
