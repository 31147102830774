import { MBProUser } from '@meilleursbiens/types';

import { MBApi } from '../../../index';

export default class MBAuthAPI {
  static getUser = () => {
    return MBApi.url(`/v1/mbpro/auth/account`).needCache(5).needAuth().get<{
      user: MBProUser;
    }>();
  };

  static searchForRegister = (search: string) => {
    return MBApi.url(`/v1/mbpro/auth/register/search`)
      .parameters({
        search: search,
      })
      .needAuth()
      .get<{
        firstName: string;
        name: string;
        email: string;
        phone: string;
      }>();
  };
}
