'use client';

import React, { useEffect } from 'react';
import { HexColorPicker } from 'react-colorful';

import { useClipboard } from '@meilleursbiens/hooks';
import { ToastUtils } from '@meilleursbiens/utils';

import { CheckIcon, CloseIcon, EditIcon } from '@chakra-ui/icons';
import {
  Box,
  ButtonGroup,
  Editable,
  EditableInput,
  EditablePreview,
  EditableTextarea,
  Fade,
  Flex,
  FormControl,
  HStack,
  IconButton,
  Input,
  InputGroup,
  InputProps,
  Popover,
  PopoverArrow,
  PopoverContent,
  PopoverTrigger,
  Select,
  Spinner,
  Stack,
  Switch,
  Text,
  Textarea,
  Tooltip,
  VStack,
  useColorModeValue,
  useDisclosure,
  useEditableControls,
} from '@chakra-ui/react';
import { HTMLChakraProps } from '@chakra-ui/system';
import { CheckCircleIcon, ClipboardDocumentIcon as ClipboardIcon } from '@heroicons/react/24/outline';
import { ExclamationTriangleIcon as ExclamationIcon } from '@heroicons/react/24/solid';

import { useDebounceValue } from 'usehooks-ts';
import * as Yup from 'yup';

import { Button } from '../../../Components';
import { DateInput } from '../../Inputs';

export interface EditableApiInputProps {
  icon: (width: number, color: string) => React.ReactNode;
  inputType:
    | 'text'
    | 'number'
    | 'email'
    | 'password'
    | 'tel'
    | 'url'
    | 'select'
    | 'textarea'
    | 'color'
    | 'onoff'
    | 'date';
  label: string;
  element: string;
  defaultValue: any;
  helperText?: string;
  editAPI: (element: string, value: string) => Promise<any>;
  customValueComponent?: React.ReactNode;
  autocomplete?: 'on' | 'off';
  inputProps?: HTMLChakraProps<'input'> & InputProps;
  options?: { value: string | number; label: string }[];
  validationRule?:
    | Yup.StringSchema<string | undefined, Record<string, any>, string>
    | Yup.NumberSchema<number | undefined, Record<string, any>, number>;
}

const EditableApiInput = ({
  inputType,
  icon,
  label,
  element,
  defaultValue,
  editAPI,
  helperText,
  autocomplete = 'off',
  inputProps = {},
  options = [],
  validationRule,
}: EditableApiInputProps) => {
  const disclosure = useDisclosure();

  const [isLoading, setIsLoading] = React.useState(false);
  const [isEditing, setIsEditing] = React.useState(false);
  const [isInitialLoad, setIsInitialLoad] = React.useState(true);

  const [value, setValue] = React.useState(defaultValue);
  const [selectedColor, setSelectedColor] = React.useState<string>(value);

  const [debouncedDate] = useDebounceValue(value, 500);

  const [hasError, setHasError] = React.useState(false);
  const [error, setError] = React.useState(null);

  const labelColor = useColorModeValue('gray.700', 'gray.400');

  const { onCopyToClipBoard } = useClipboard(value);

  useEffect(() => {
    setTimeout(() => {
      setIsInitialLoad(false);
    }, 2500);
  }, []);

  useEffect(() => {
    if (isInitialLoad) return;
    if (isEditing) return;
    if (inputType === 'select' && defaultValue != value) {
      _onEditAPI(value);
    }
  }, [value]);

  useEffect(() => {
    if (isInitialLoad) return;
    if (inputType === 'date') {
      if (defaultValue != debouncedDate) {
        _onEditAPI(value);
      }
    }
  }, [debouncedDate]);

  const _onEditAPI = async (valuet: any) => {
    if (isInitialLoad) return;

    setValue(valuet);
    if (!_validation(valuet, true)) return;

    setHasError(false);
    setIsLoading(true);
    try {
      await editAPI(element, valuet);
    } catch (e) {
      //ToastUtils.showError("Une erreur s'est produite, veuillez réessayer.");
      console.error(e);
    }
    setIsLoading(false);
  };

  const _validation = (value: string, isEndEditing = false) => {
    if (isInitialLoad) return;
    if (isEndEditing) setIsEditing(false);

    if (validationRule) {
      const validationSchema = Yup.object().shape({
        [element]: validationRule,
      });

      try {
        validationSchema.validateSync({ [element]: value });
        setHasError(false);
        setError(null);

        return true;
      } catch (e) {
        setHasError(true);
        // @ts-ignore
        setError(e.errors[0]);
        return false;
      }
    }

    return true;
  };

  function EditableControls() {
    const { isEditing, getSubmitButtonProps, getCancelButtonProps, getEditButtonProps } = useEditableControls();

    return isEditing ? (
      <ButtonGroup justifyContent="center" size="xs" isAttached>
        <IconButton aria-label={'Annuler'} icon={<CloseIcon />} {...getCancelButtonProps()} />
        <IconButton aria-label={'Sauvegarder'} icon={<CheckIcon />} {...getSubmitButtonProps()} />
      </ButtonGroup>
    ) : (
      <ButtonGroup justifyContent="center" size="xs" isAttached>
        <IconButton
          aria-label={'Copier'}
          icon={<ClipboardIcon width={15} />}
          onClick={() => {
            onCopyToClipBoard();
            ToastUtils.showInfo('Valeur copiée dans le presse papier');
          }}
        />
        <IconButton aria-label={'Editer'} icon={<EditIcon />} {...getEditButtonProps()} />
      </ButtonGroup>
    );
  }

  function TypeAreaInput() {
    // @ts-ignore
    if (['textarea'].includes(inputType)) {
      return (
        <Editable
          isDisabled={isLoading}
          onEdit={() => setIsEditing(true)}
          defaultValue={value}
          w={'100%'}
          onCancel={() => setIsEditing(false)}
          onSubmit={(v) => _onEditAPI(v)}
        >
          <Flex direction={'row'} justify={'space-between'} align={'center'} w={'100%'}>
            <EditablePreview
              fontWeight={400}
              pl={1}
              h={'32px'}
              pt={'6px'}
              color={useColorModeValue('gray.700', 'white')}
              fontSize={'13px'}
              w={'100%'}
              noOfLines={1}
              isTruncated={true}
            />
            <HStack w={'100%'}>
              <FormControl>
                <InputGroup>
                  {/* @ts-ignore */}
                  <Textarea
                    w={'100%'}
                    type={inputType}
                    boxShadow={'base'}
                    isInvalid={hasError}
                    fontSize="13px"
                    autoComplete={autocomplete}
                    as={EditableTextarea}
                    {...inputProps}
                  >
                    {inputProps.value || defaultValue}
                  </Textarea>
                </InputGroup>
              </FormControl>
              {isLoading ? <Spinner size={'sm'} pr={2} /> : <EditableControls />}
            </HStack>
          </Flex>
        </Editable>
      );
    }

    return <></>;
  }

  function TypeInput() {
    // @ts-ignore
    if (['text', 'number', 'email', 'password', 'url', 'tel'].includes(inputType)) {
      return (
        <Input
          w={'100%'}
          size={'sm'}
          pl={2}
          type={inputType}
          variant={'filled'}
          focusBorderColor="transparent"
          boxShadow={'none'}
          isInvalid={hasError}
          fontSize="13px"
          autoComplete={autocomplete}
          as={EditableInput}
          {...inputProps}
        />
      );
    }

    return <></>;
  }

  function TypeSelect() {
    if (inputType === 'select') {
      return (
        <HStack w={'100%'}>
          <Select
            w={'100%'}
            size={'sm'}
            variant={'filled'}
            bg={useColorModeValue('gray.50', 'gray.700')}
            focusBorderColor="red.50"
            boxShadow={'none'}
            isInvalid={hasError}
            fontSize="13px"
            fontWeight={500}
            autoComplete={autocomplete}
            onChange={(e) => setValue(e.target.value)}
            value={value}
          >
            {options.map((option, index) => (
              <option key={index} value={option.value}>
                {option.label}
              </option>
            ))}
          </Select>
          {isLoading && <Spinner size={'sm'} pr={2} />}
        </HStack>
      );
    }

    return <></>;
  }

  function TypeOnOff() {
    if (inputType === 'onoff') {
      return (
        <Flex justify={'flex-end'} w={'100%'}>
          <Fade in={!isLoading}>
            <Switch
              colorScheme={'green'}
              isChecked={value}
              onChange={(e) => {
                setValue(e.target.checked ? 1 : 0);
                _onEditAPI(e.target.checked ? 1 : 0);
              }}
            />
          </Fade>
          <Fade unmountOnExit in={isLoading}>
            <Spinner size={'sm'} pr={2} />
          </Fade>
        </Flex>
      );
    }

    return <></>;
  }

  function TypeDateInput() {
    if (inputType === 'date') {
      return (
        <HStack w={'100%'}>
          <DateInput
            name={'date'}
            handleChange={(e) => {
              console.log('Change', e.target.value);
              setValue(e.target.value);
            }}
            handleBlur={(e) => {
              setValue(e.target.value);
            }}
            values={{
              date: value,
            }}
            errors={{}}
            touched={{
              date: false,
            }}
            w={'100%'}
            variant={'filled'}
            bg={useColorModeValue('gray.50', 'gray.700')}
            focusBorderColor="red.50"
            boxShadow={'none'}
            isInvalid={hasError}
            fontSize="13px"
            fontWeight={500}
          />
          {isLoading && <Spinner size={'sm'} pr={2} />}
        </HStack>
      );
    }

    return <></>;
  }

  function ColorInput() {
    if (inputType === 'color') {
      return (
        <Popover
          strategy={'fixed'}
          isOpen={disclosure.isOpen}
          closeOnBlur={true}
          onClose={() => {
            setValue(selectedColor);
            _onEditAPI(selectedColor);
            disclosure.onClose();
          }}
        >
          <PopoverTrigger>
            <HStack
              cursor={'pointer'}
              maxW={'120px'}
              bg={useColorModeValue('gray.50', 'gray.700')}
              w={'100%'}
              spacing={3}
              borderRadius={'md'}
              py={1}
              px={1}
              onClick={() => disclosure.onOpen()}
            >
              <Box w={'44px'} h={'28px'} borderRadius={'md'} bg={selectedColor} />
              <Text fontSize={13} fontWeight={500} color={useColorModeValue('gray.700', 'white')} w={'100%'}>
                {value}
              </Text>
            </HStack>
          </PopoverTrigger>
          <PopoverContent zIndex={99999} w={'202px'}>
            <PopoverArrow />
            <HexColorPicker color={selectedColor} onChange={setSelectedColor} />
            <Button
              variant={'primary'}
              borderTopRadius={0}
              rightIcon={CheckCircleIcon}
              onClick={() => {
                setValue(selectedColor);
                _onEditAPI(selectedColor);
                disclosure.onClose();
              }}
            >
              Valider
            </Button>
          </PopoverContent>
        </Popover>
      );
    }

    return <></>;
  }

  const textInputsTypes = ['text', 'number', 'email', 'password', 'url', 'tel'];

  const leftPartWidth = inputType == 'onoff' ? '90%' : '35%';
  const rightPartWidth = inputType == 'onoff' ? '10%' : '65%';

  return (
    <Flex
      direction={'row'}
      justify={'flex-start'}
      align={'center'}
      w={'100%'}
      maxW={'600px'}
      py={2}
      borderBottomWidth={1}
      borderBottomColor={useColorModeValue('gray.50', 'gray.800')}
    >
      <Tooltip label={helperText}>
        <HStack w={leftPartWidth} pr={3}>
          <Stack spacing={1}>
            <HStack spacing={2}>
              {icon(14, useColorModeValue('muted', 'muted'))}
              <Text
                fontWeight={500}
                letterSpacing={'-0.02em'}
                fontSize={13}
                color={labelColor}
                style={{ marginBottom: 0 }}
                noOfLines={2}
                lineHeight={'1.1em'}
              >
                {label}
              </Text>
            </HStack>
          </Stack>
        </HStack>
      </Tooltip>
      {textInputsTypes.includes(inputType) ? (
        <VStack w={'65%'} maxW={'400px'}>
          <Tooltip label={'Cliquez pour modifier la valeur'} isDisabled={isLoading || isEditing}>
            <HStack
              w={'100%'}
              px={1}
              py={1}
              bg={useColorModeValue('gray.50', 'gray.700')}
              transition={'all 0.1s ease'}
              borderRadius={'md'}
              _hover={{ bg: useColorModeValue('gray.100', 'gray.800') }}
            >
              <Editable
                isDisabled={isLoading}
                onEdit={() => setIsEditing(true)}
                defaultValue={value}
                w={'100%'}
                onCancel={() => setIsEditing(false)}
                onSubmit={(v) => _onEditAPI(v)}
              >
                <Flex direction={'row'} justify={'space-between'} align={'center'} w={'100%'}>
                  <EditablePreview
                    fontWeight={400}
                    pl={2}
                    h={'24px'}
                    color={useColorModeValue('gray.700', 'white')}
                    fontSize={'12px'}
                    w={'100%'}
                    noOfLines={1}
                  />
                  <HStack w={'100%'}>
                    <FormControl>
                      <InputGroup>
                        <TypeInput />
                      </InputGroup>
                    </FormControl>
                    {isLoading ? <Spinner size={'sm'} pr={2} /> : <EditableControls />}
                  </HStack>
                </Flex>
              </Editable>
            </HStack>
          </Tooltip>
          {hasError && (
            <HStack w={'100%'}>
              <ExclamationIcon color={'#BB2030'} width={20} />
              <Text fontSize={12} color={'red.600'} textAlign={'left'} w={'100%'}>
                {error}
              </Text>
            </HStack>
          )}
        </VStack>
      ) : (
        <>
          {inputType === 'onoff' && (
            <VStack w={rightPartWidth} maxW={'400px'}>
              <TypeOnOff />
            </VStack>
          )}
          {inputType === 'select' && (
            <VStack w={rightPartWidth} maxW={'400px'}>
              <TypeSelect />
            </VStack>
          )}
          {inputType === 'textarea' && (
            <VStack w={rightPartWidth} maxW={'400px'}>
              <TypeAreaInput />
            </VStack>
          )}
          {inputType === 'color' && <ColorInput />}
          {inputType === 'date' && (
            <VStack w={rightPartWidth} maxW={'400px'}>
              <TypeDateInput />
            </VStack>
          )}
        </>
      )}
    </Flex>
  );
};

export default EditableApiInput;
