import {
  MBCoreTeam,
  MBEstimation,
  MBNeoPropertiesType,
  MBProMandate,
  MBProTransaction,
  MBProTransactionLocation,
  MBProUser,
  MBSupportTicket,
} from '@meilleursbiens/types';

import { MBApi } from '../../../index';

export interface MBProAdminSearchAPIResponse {
  users: MBProUser[];
  transactions: MBProTransaction[];
  transactions_location: MBProTransactionLocation[];
  properties: MBNeoPropertiesType[];
  estimations: MBEstimation[];
  mandates: MBProMandate[];
  tickets: MBSupportTicket[];
  teams: MBCoreTeam[];
}

export default class MBProAdminSearchAPI {
  static search(search: string) {
    return MBApi.url('/v2/mbpro/admin/search').parameters({ search }).needAuth().post<MBProAdminSearchAPIResponse>();
  }
}
