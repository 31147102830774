interface AuthConstants {
  BACKUP_KEY: string;
  IMPERSONATE_USER_SAVE: string;
  JWT_TOKEN_KEY: string;
  JWT_REFRESH_TOKEN_KEY: string;
  MB_ID_KEY: string;
  CRISP_SIGNATURE: string;
  HEADERS: {
    AUTHORIZATION: string;
    REFRESH_TOKEN: string;
    MB_ID: string;
  };
}

export default <AuthConstants>{
  BACKUP_KEY: 'mb_save_',
  IMPERSONATE_USER_SAVE: 'meilleursbiens_user_save',
  JWT_TOKEN_KEY: 'meilleursbiens_jwt_token',
  JWT_REFRESH_TOKEN_KEY: 'meilleursbiens_jwt_refresh_token',
  MB_ID_KEY: 'meilleursbiens_user_id',
  CRISP_SIGNATURE: 'meilleursbiens_crisp_token',
  HEADERS: {
    AUTHORIZATION: 'Authorization',
    REFRESH_TOKEN: 'Refresh-Token',
    MB_ID: 'MeilleursBiens-User-Id',
  },
};
